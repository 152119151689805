/* eslint-disable eqeqeq */
import { useEffect, useRef, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";
import Update from "./Update";
import $ from 'jquery';
import Delete from "./Delete";
import Create from "./Create";
import useAuth from "../../../Hooks/useAuth";
$.DataTable = require('datatables.net-bs5');


function Table() {

  const { auth } = useAuth();
  const tableRef = useRef()
  const tableName = "table-properties"
  const url = "/properties";
  const [data, setData] = useState();
  
  const [categories, setCategories] = useState();

  const [updateId, setUpdateId] = useState("");
  const [propertyData, setPropertyData] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [imageId, setImageId] = useState("");
  const [updateCountry, setUpdateCountry] = useState("");
  const [updateState, setUpdateState] = useState("");

  let actions = `<button class="btn edit-btn" data-toggle="modal" data-target="#propertyUpdateModal" ><i class="ti-pencil text-primary" ></i> </button> <b> - </b>                          
                  <button class="btn delete-btn" data-toggle="modal" data-target="#propertyDeleteModal"><i class="ti-trash text-danger"></i> </button>`;
  if(auth.roleId == 2){
    actions = `<button class="btn edit-btn" data-toggle="modal" data-target="#propertyUpdateModal" ><i class="ti-pencil text-primary" ></i> </button>`;
  }

  const columns =  [
      { title: "ID" },
      { title: "Name" },
      { title: "Email" },
      { title: "Telephone" },
      { title: "Address" },
      { title: "Category" },
      { title: "Status" },
      { title: "Description" },
      { title: "User" },
      { title: "Actions" },
    ];

  async function getAll(){
    try{

      // Axios API.
      const response = await axiosPrivate.get(url);
      const categories = await axiosPrivate.get("/categories");
      

      const formatedData = await response?.data?.map((property) => {

        if(auth.roleId == 2){
          if(property.userId == auth.userId){
            console.log(auth.userId);
            return [
              property.id,
              property.name,
              property?.email,
              property?.telephone,
              property.address,
              (property?.Category?.name || " - "),
              property.status,
              property.description || " - ",
              property?.User?.firstName+" "+property?.User?.lastName,
              property.userId,
              property.categoryId,
              property.stateId,
              property.State,
            ]
          }
          return false;
        }else{
          return [
            property.id,
            property.name,
            property?.email,
            property?.telephone,
            property.address,
            property?.Category?.name,
            property.status,
            property.description || " - ",
            property?.User?.firstName+" "+property?.User?.lastName,
            property.userId,
            property.categoryId,
            property.stateId,
            property.State,
          ]
        }
        
      });

      setCategories(categories.data);
      setData(formatedData.filter(function (item) {
        return item != false;
      }));

    }catch(e){
      console.error(e);
    }
  }

  async function setUpdateInformation(data){
    setUpdateId(data[0]);

    setPropertyData(data);
    const countriesResponse =  await axiosPrivate.get("/countries");
    const imagesResponse =  await axiosPrivate.post("/properties/images",
    JSON.stringify({
      propertyId: data[0]
    }));

    setImageId(imagesResponse.data[0]?.id);
    setUpdateImage(imagesResponse.data[0]?.url);
    
    if(countriesResponse.status == 200){
      const optionsArray = countriesResponse.data.map((item)=> {
        let selected = false;
        if(item.id == data[10].countryId) { 
          selected = "selected"; 
        }
        return (<option key={item.id} value={item.id} selected={selected}>{item.name}</option>) 
      });
      setUpdateCountry(optionsArray);
      
      let statesArray = data[10].Country.States.map((item) => {
        let selected = false;
        if(item.id == data[10].id) { 
          selected = "selected"; 
        }
        return (<option key={item.id} value={item.id} selected={selected}>{item.name}</option>) 
      });
      setUpdateState(statesArray);
      console.log(data[10]);
    }

  }

  function setDeleteInformation(data){
    setUpdateId(data[0]);
  }

  useEffect(() => { getAll() }, []);
  
  const table = $(tableRef.current).DataTable({
    data: data,
    columns: columns,
    dom: "<'d-flex justify-content-between'<B><f>>rt<'d-flex justify-content-between'<l><p>>i<'clear'>",
    pageLength: 5,
    lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
    buttons: [
      { extend: 'csv', className: 'btn btn-sm btn-dark' },
    ],
    columnDefs: [
      {
          targets: -1,
          data: null,
          render: function () {
            return actions;
        }
      },
    ],
    drawCallback: () => {
      $('.form-control').addClass('form-control-sm');
      $('.active > .page-link').addClass('bg-dark');
    },
    destroy: true,     
  });
  $('#table-properties tbody').on('click', 'tr', function () {
    if ($(this).parent("tr").hasClass('selected')) {
        $(this).removeClass('selected');
    } else {
        table.$("tr.selected").removeClass('selected');
        $(this).addClass('selected');
    }
  });
  $("#table-properties tbody").on("click", "button", function () {
    let data = table.row($(this).parents("tr")).data();
    if(data) setUpdateInformation(data)
    if(data) setDeleteInformation(data)
  });
  


  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h4 className="card-title text-danger">All Properties</h4>
              <button type="button" className="btn btn-danger btn-sm btn-icon-text" data-toggle="modal" data-target="#propertyModal">
                <i className="ti-file btn-icon-prepend"></i> Add Property
              </button>
            </div>
            <div className="table-responsive pt-3">
              <table className="display table table-striped" width="100%" id={ tableName } ref={ tableRef }></table>
            </div>
          </div>
        </div>
      </div>
      <Create opt={categories} />
      <Update opt={categories} propertyId={updateId} propertyData={propertyData} images={updateImage} imageId={imageId} countries={updateCountry} states={updateState} />
      <Delete propertyId={updateId} />
    </>
  );

}
export default Table;