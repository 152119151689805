import { useEffect, useRef, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";
import Update from "./Update";
import $ from 'jquery';
import Delete from "./Delete";
import Create from "./Create";
$.DataTable = require('datatables.net-bs5');


function Table() {

  const tableRef = useRef()
  const tableName = "table-location"
  const url = "/states";
  const [data, setData] = useState();
  
  const [countries, setCountries] = useState();

  const [updateId, setUpdateId] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateCountry, setUpdateCountry] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [imageId, setImageId] = useState("");

  const actions = `<button class="btn edit-btn" data-toggle="modal" data-target="#locationUpdateModal" ><i class="ti-pencil text-primary" ></i> </button> <b> - </b>                          
                  <button class="btn delete-btn" data-toggle="modal" data-target="#locationDeleteModal"><i class="ti-trash text-danger"></i> </button>`;

  const columns =  [
      { title: "ID" },
      { title: "State" },
      { title: "Country" },
      { title: "Abbreviation" },
      { title: "Code" },
      { title: "Currency" },
      { title: "Actions" },
    ];

  async function getAll(){
    try{
      // Axios API.
      const response = await axiosPrivate.get(url);
      const countriesResponse = await axiosPrivate.get("/countries");
      const formatedData = await response?.data?.map((location) => {
        return [
          location.id,
          location.name,
          location.Country.name,
          location.Country.abbreviation || " - ",
          location.Country.phoneCode || " - ",
          location.Country.symbol || " - ",
        ]
      })

      setCountries(countriesResponse.data);
      setData(formatedData);

    }catch(e){
      console.error(e);
    }
  }

  async function setUpdateInformation(data){
     setUpdateId(data[0]);
     setUpdateName(data[1]);
     setUpdateCountry(data[2]);

     const imagesResponse =  await axiosPrivate.post("/states/images",
      JSON.stringify({
        stateId: data[0]
      }));

    setImageId(imagesResponse.data[0]?.id);
    setUpdateImage(imagesResponse.data[0]?.url);
  }

  function setDeleteInformation(data){
    setUpdateId(data[0]);
  }

  useEffect(() => { getAll() }, []);
  
  const table = $(tableRef.current).DataTable({
    data: data,
    columns: columns,
    dom: "<'d-flex justify-content-between'<B><f>>rt<'d-flex justify-content-between'<l><p>>i<'clear'>",
    pageLength: 5,
    lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
    buttons: [
      { extend: 'csv', className: 'btn btn-sm btn-dark' },
    ],
    columnDefs: [
      {
          targets: -1,
          data: null,
          render: function () {
            return actions;
        }
      },
    ],
    drawCallback: () => {
      $('.form-control').addClass('form-control-sm');
      $('.active > .page-link').addClass('bg-dark');
    },
    destroy: true,     
  });
  $('#table-location tbody').on('click', 'tr', function () {
    if ($(this).parent("tr").hasClass('selected')) {
        $(this).removeClass('selected');
    } else {
        table.$("tr.selected").removeClass('selected');
        $(this).addClass('selected');
    }
  });
  $("#table-location tbody").on("click", "button", function () {
    let data = table.row($(this).parents("tr")).data();
    if(data) setUpdateInformation(data)
    if(data) setDeleteInformation(data)
  });
  


  return (
    <>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title text-danger">All Locations</h4>
                <button type="button" className="btn btn-danger btn-sm btn-icon-text" data-toggle="modal" data-target="#locationModal">
                  <i className="ti-file btn-icon-prepend"></i> Add Location
                </button>
              </div>
              <div className="table-responsive pt-3">
                <table className="display table table-striped" width="100%" id={tableName} ref={ tableRef }></table>
              </div>
            </div>
          </div>
        </div>
        <Create opt={countries} />
        <Update opt={countries} locationId={updateId} name={updateName} country={updateCountry} images={updateImage} imageId={imageId} />
        <Delete locationId={updateId} />
    </>
  );

}
export default Table;