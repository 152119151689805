import { useEffect, useRef, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";
import Update from "./Update";
import $ from 'jquery';
import Delete from "./Delete";
import Create from "./Create";
$.DataTable = require('datatables.net-bs5');


function Table() {

  const tableRef = useRef()
  const tableName = "table-roles"
  const url = "/roles";
  const [data, setData] = useState();
  
  const [permissions, setPermissions] = useState();

  const [updateId, setUpdateId] = useState("");
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateSlug, setUpdateSlug] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [updatePermission, setUpdatePermission] = useState("");

  const actions = `<button class="btn edit-btn" data-toggle="modal" data-target="#roleUpdateModal" ><i class="ti-pencil text-primary" ></i> </button> <b> - </b>                          
                  <button class="btn delete-btn" data-toggle="modal" data-target="#roleDeleteModal"><i class="ti-trash text-danger"></i> </button>`;

  const columns =  [
      { title: "ID" },
      { title: "Title" },
      { title: "Slug" },
      { title: "Description" },
      { title: "Permissions" },
      { title: "Actions" },
    ];

  async function getAll(){
    try{
      // Axios API.
      const response = await axiosPrivate.get(url);
      const permissionResponse = await axiosPrivate.get("/permissions");
      const formatedData = await response?.data?.map((role) => {
        let permissionList = role.Permissions.map((item) => item.title)
        return [
          role.id,
          role.title,
          role.slug,
          role.description,
          permissionList,
        ]
      });

      setPermissions(permissionResponse.data);
      setData(formatedData);

    }catch(e){
      console.error(e);
    }
  }

  function setUpdateInformation(data){
     setUpdateId(data[0]);
     setUpdateTitle(data[1]);
     setUpdateSlug(data[2]);
     setUpdateDescription(data[3]);
     setUpdatePermission(data[4]);
  }

  function setDeleteInformation(data){
    setUpdateId(data[0]);
  }

  useEffect(() => { getAll() }, []);
  
  const table = $(tableRef.current).DataTable({
    data: data,
    columns: columns,
    dom: "<'d-flex justify-content-between'<B><f>>rt<'d-flex justify-content-between'<l><p>>i<'clear'>",
    pageLength: 5,
    lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
    buttons: [
      { extend: 'csv', className: 'btn btn-sm btn-dark' },
    ],
    columnDefs: [
      {
          targets: -1,
          data: null,
          render: function () {
            return actions;
        }
      },
    ],
    drawCallback: () => {
      $('.form-control').addClass('form-control-sm');
      $('.active > .page-link').addClass('bg-dark');
    },
    destroy: true,     
  });
  $('#table-roles tbody').on('click', 'tr', function () {
    if ($(this).parent("tr").hasClass('selected')) {
        $(this).removeClass('selected');
    } else {
        table.$("tr.selected").removeClass('selected');
        $(this).addClass('selected');
    }
  });
  $("#table-roles tbody").on("click", "button", function () {
    let data = table.row($(this).parents("tr")).data();
    if(data) setUpdateInformation(data)
    if(data) setDeleteInformation(data)
  });
  


  return (
    <>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title text-danger">All Roles</h4>
                <button type="button" className="btn btn-danger btn-sm btn-icon-text" data-toggle="modal" data-target="#roleModal">
                  <i className="ti-file btn-icon-prepend"></i> Add Role
                </button>
              </div>
              <div className="table-responsive pt-3">
                <table className="display table table-striped" width="100%" id={tableName} ref={ tableRef }></table>
              </div>
            </div>
          </div>
        </div>
        <Create opt={permissions} />
        <Update opt={permissions} roleId={updateId} title={updateTitle} slug={updateSlug} description={updateDescription} permissions={updatePermission} />
        <Delete roleId={updateId} />
    </>
  );

}
export default Table;