/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../../Apis/axios';
import { Widget } from '@uploadcare/react-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';
import useAuth from '../../../Hooks/useAuth';
import Select from "react-select";

const UPLOAD_PUBLIC_KEY = "d56c8b8e1e84d37ee09a";

function Create(props) {
    const url = "/rooms";
    const { auth } = useAuth();
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Create");
    const [imageValue, setImageValue] = useState("");
    const [properties, setProperties] = useState();


    async function getProperties(){
        try{
          const propertiesResponse =  await axiosPrivate.get("/properties");
          // Print out responses
          let propertyList = propertiesResponse?.data.map((item) => { return { value: item.id, label: item.name, userId: item.userId } });
          if(auth.roleId == 2){
            // eslint-disable-next-line array-callback-return
            setProperties(propertyList.filter(item => item.userId == auth.userId));
        }else {
              setProperties(propertyList);

          }
        }catch(e){
          console.error(e);
        }
    }
    
    useEffect(()=>{
        getProperties();
    }, []);
    

    async function handleCreate(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)
        
        //console.log(auth.userId);

        const inputValues = {
            propertyId: event.target.elements.property.value,
            roomType: event.target.elements.roomName.value,
            status: event.target.elements.status.value,
            beds: event.target.elements.beds.value,
            adults: event.target.elements.adults.value,
            children: event.target.elements.children.value,
            limit: parseInt(event.target.elements.limit.value),
            price: event.target.elements.price.value,
            pricePerHour: event.target.elements.priceHr.value,
            discount: event.target.elements.discount.value,
        }    

        console.log(inputValues);
        
        try{
            // Axios API.
            const response = await axiosPrivate.post(url, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
                
                // eslint-disable-next-line eqeqeq
                if ((response.status === 200) && (imageValue != "")){
                    const uploadsInputValues = {
                        userId: auth.userId,
                        typeId: response.data.data.id,
                        type: 'room',
                        format: 'image/url',
                        url: imageValue.cdnUrl,
                    }  
                    await axiosPrivate.post("/uploads", JSON.stringify(uploadsInputValues));
                }
                
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Create");
            // Print Out Responses.
            setSuccess("Room created successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Create");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }

  return (
    <div className="modal fade" id="roomModal" tabIndex="-1" role="dialog" aria-labelledby="#roomModal" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content border-0">
                <form onSubmit={handleCreate}>
                    <div className="modal-header py-2 text-white bg-dark">
                        <h5 className="modal-title" id="exampleModalLongTitle">Create Room</h5>
                    </div>
    
                    <div className="modal-body py-3">
                    <span className="text-danger small"><b>{error}</b></span>
                    <span className="text-success small"><b>{success}</b></span>

                        <div className="row py-3">
                            <div className="col-md-3 px-2">
                                <label htmlFor="propertyInput" className='font-weight-medium'>Property Name: <span className='text-danger'>*</span></label>
                                <Select name="property" options={ properties } />
                            </div>
                            
                            <div className="col-md-5 px-2">
                                <label htmlFor="nameInput" className='font-weight-medium'>Room Name: <span className='text-danger'>*</span></label>
                                <input type="text" id='nameInput' name='roomName' className='form-control form-control-sm'  placeholder='Name' required />
                            </div>
                        </div>

                        <div className="row py-3">
                            
                            <div className="col-md-3 px-2">
                                <label htmlFor="status" className='font-weight-medium'>Status: <span className='text-danger'>*</span></label>
                                <select name="status" id="status" className='form-control form-control-sm'>
                                    <option value="pending">Pending</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                            
                            <div className="col-md-2 px-2">
                                <label htmlFor="beds" className='font-weight-medium'>Beds: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="beds" min={0} id="beds" placeholder="beds" required />
                            </div>

                            <div className="col-md-2 px-2">
                                <label htmlFor="adults" className='font-weight-medium'>Adults: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="adults" min={0} id="adults" placeholder="adults" required />
                            </div>

                            <div className="col-md-2 px-2">
                                <label htmlFor="children" className='font-weight-medium'>Children: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="children" min={0} id="children" placeholder="children" required />
                            </div>
                            
                            <div className="col-md-2 px-2">
                                <label htmlFor="quantity" className='font-weight-medium'>Quantity: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="limit" min={0} id="quantity" placeholder="quantity" required />
                            </div>

                        </div>
                       
                        <div className="row py-3">

                            <div className="col px-2">
                                <label htmlFor="price" className='font-weight-medium'>Price: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="price" min={0} id="price" placeholder="price" required />
                            </div>

                            <div className="col px-2">
                                <label htmlFor="price-hr" className='font-weight-medium'>Price/Hr: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="priceHr" min={0} id="price-hr" placeholder="Price/Hr" required />
                            </div>

                            <div className="col px-2">
                                <label htmlFor="discount" className='font-weight-medium'>Discount (%): <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="discount" min={0} id="discount" placeholder="Discount (%)" required />
                            </div>

                            <div className="col-md-4 px-2">
                                <label htmlFor="images" className='font-weight-medium'>Room Images: <span className='text-danger'></span></label>
                                <br />
                                <Widget 
                                    publicKey={UPLOAD_PUBLIC_KEY}
                                    tabs='file'
                                    customTabs={{preview: uploadcareTabEffects}}
                                    previewStep='true'
                                    imagesOnly='true'
                                    multiple='true'
                                    multipleMax='3'
                                    effects='crop'
                                    multipartPartSize='10480'
                                    onChange={(file) => setImageValue(file)}
                                    crop='free, 16:9, 4:3, 5:4, 1:1'
                                    required
                                />
                            </div>

                        </div>
                        
                    </div>

                    <div className="modal-footer py-1">
                        <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                        <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                            {submit}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
}

export default Create;