import React, { useEffect, useState } from 'react'
import { axiosPrivate } from '../../../Apis/axios';
import { Widget } from '@uploadcare/react-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';
import useAuth from '../../../Hooks/useAuth';

const UPLOAD_PUBLIC_KEY = "d56c8b8e1e84d37ee09a";

function Update(props) {

    const url = "/properties";
    const { auth } = useAuth();
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Update");
    const [imageValue, setImageValue] = useState("");
    const [options, setOptions] = useState();
    const [statusOpts, setStatusOpts] = useState();
    const [states, setStates] = useState();

    if (props.propertyData[6] == 'active'){
        setTimeout(() => setStatusOpts(<><option value="active">Active</option><option value="inactive">Inactive</option><option value="pending">Pending</option></>), 100);
    }else if(props.propertyData[6] == 'inactive'){
        setTimeout(() => setStatusOpts(<><option value="inactive">Inactive</option><option value="pending">Pending</option><option value="active">Active</option></>), 100);
    }else{
        setTimeout(() => setStatusOpts(<><option value="pending">Pending</option><option value="inactive">Inactive</option><option value="active">Active</option></>), 100);
    }

    async function getPropertyState(){

        if (props?.propertyId) {
            try{
                const response = await axiosPrivate.get(url+"/"+props?.propertyId);
                
                const statesResponse = await axiosPrivate.get("/states/country/"+response?.data?.State?.countryId);

                const stateList = statesResponse?.data.map((item) => {
                    let selected = "";
                    if(response?.data?.State.id == item.id){ selected="selected"}

                    return (
                        <option key={item.id} value={item.id} selected={selected}>
                            {item.name}
                        </option>
                    )
                });
                setStates(stateList);

            }catch(e){
            console.error(e);
            }
        } else{
            setStates("");
        }
    }

    useEffect(()=>{
        getPropertyState();
        let opt = [];
        if(props.hasOwnProperty("opt")) opt = props.opt; 
        if(opt){
            const optionsArray = opt.map((item)=> {
                let selected = false;
                if(item.id == props.propertyData[10]){ selected = "selected"; }
                return (<option key={item.id} value={item.id} selected={selected}>{item.name}</option>) 
            });
            setOptions(optionsArray);
        }

    }, [props])

    
    async function getStates(event){
        const countryId = event.target.value
        if (countryId > 0) {
          try{
            const statesResponse = await axiosPrivate.get("/states/country/"+countryId);
            const stateList = statesResponse?.data.map((item) => {
              return(
                <option key={item.id} value={item.id}>
                {item.name}
              </option>
            )
          });
          setStates(stateList);
          }catch(e){
            console.error(e);
          }
        }else{
          setStates("");
        }
    }
    

    async function handleUpdate(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)

        const inputValues = {
            id: event.target.elements.id.value,
            name: event.target.elements.name.value,
            email: event.target.elements.email.value,
            telephone: event.target.elements.telephone.value,
            categoryId: event.target.elements.category.value,
            stateId: event.target.elements.state.value,
            userId: auth.userId,
            status: event.target.elements.status.value,
            address: event.target.elements.address.value,
            description: event.target.elements.description.value
        }    

        try{
            // Axios API.
            const response = await axiosPrivate.put(url+"/"+inputValues.id, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");

            if ((response.status === 200) && (imageValue != "")){
                    
                const uploadsInputValues = {
                    id: event.target.elements.imageId.value,
                    userId: auth.userId,
                    typeId: inputValues.id,
                    type: 'property',
                    format: 'image/url',
                    url: imageValue.cdnUrl,
                }
                // console.log(uploadsInputValues);
                // console.log("Here "+response);

                if(event.target.elements.imageId.value != ""){
                    await axiosPrivate.put("/uploads/"+uploadsInputValues.id, JSON.stringify(uploadsInputValues));
                }else{
                    await axiosPrivate.post("/uploads", JSON.stringify(uploadsInputValues));
                }
            }

            // Print Out Responses.
            setSuccess("Category updated successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }

    return (
        <div className="modal fade" id="propertyUpdateModal" tabIndex="-1" role="dialog" aria-labelledby="#propertyUpdateModal" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content border-0">
                <form onSubmit={handleUpdate}>
                    <div className="modal-header py-2 text-white bg-dark">
                        <h5 className="modal-title" id="exampleModalLongTitle">Update Property</h5>
                    </div>
    
                    <div className="modal-body py-3">
                    <span className="text-danger small"><b>{error}</b></span>
                    <span className="text-success small"><b>{success}</b></span>
                    <input type="hidden" name='id' defaultValue={props.propertyId} />
                    <input type="hidden" name='imageId' defaultValue={props.imageId} />

                        <div className="row py-3">

                            <div className="col-md-6 px-2">
                                <label htmlFor="nameInput" className='font-weight-medium'>Name: <span className='text-danger'>*</span></label>
                                <input type="text" id='nameInput' defaultValue={props.propertyData[1]} name='name' className='form-control form-control-sm'  placeholder='Name' required />
                            </div>

                            <div className="col px-2">
                                <label htmlFor="status" className='font-weight-medium'>Status: <span className='text-danger'>*</span></label>
                                <select name="status" id="status" className='form-control form-control-sm'>
                                    {statusOpts}
                                </select>
                            </div>

                            <div className="col px-2">
                                <label htmlFor="category" className='font-weight-medium'>Category: <span className='text-danger'>*</span></label>
                                <select name="category" id="category" className='form-control form-control-sm'>
                                    {options}
                                </select>
                            </div>

                        </div>

                        <div className="row py-3">
                            <div className="col-md-4 px-2">
                                <label className='font-weight-medium'>Email: <span className='text-danger'>*</span></label>
                                <input type="email" name='email' defaultValue={props.propertyData[2]}  className='form-control form-control-sm'  placeholder='Email' required />
                            </div>
                            
                            <div className="col-md-4 px-2">
                                <label className='font-weight-medium'>Telephone: <span className='text-danger'></span></label>
                                <input type="tel" name='telephone' defaultValue={props.propertyData[3]}  className='form-control form-control-sm'  placeholder='Telephone' />
                            </div>
                        </div>

                        <div className="row py-3">

                            <div className="col px-2">
                                <label htmlFor="country" className='font-weight-medium'>Country: <span className='text-danger'>*</span></label>
                                <select name="country" id="country" onChange={getStates} className='form-control form-control-sm'>
                                    {props.countries}
                                </select>
                            </div>

                            <div className="col px-2">
                                <label htmlFor="state" className='font-weight-medium'>State: <span className='text-danger'>*</span></label>
                                <select name="state" id="state" className='form-control form-control-sm'>
                                    {/* <option value="">None</option> */}
                                    {states}
                                </select>
                            </div>

                            <div className="col-md-6 px-2">
                                <label htmlFor="address" className='font-weight-medium'>Address: <span className='text-danger'>*</span></label>
                                <input type="text" id='address' defaultValue={props.propertyData[4]} name='address' className='form-control form-control-sm'  placeholder='Address' required />
                            </div>

                        </div>
                        
                        <div className="row  py-3">

                            <div className="col-md-6 px-2">
                                <label htmlFor="description" className='font-weight-medium'>Description: <span className='text-danger'></span></label>
                                <textarea name="description"  defaultValue={props.propertyData[7]} className='form-control form-control-sm' id="description" cols="30" rows="3"></textarea>
                            </div>

                            <div className="col-md-6 px-2">
                                <label htmlFor="images" className='font-weight-medium'>Property Images: <span className='text-danger'></span></label>
                                <br />
                                <Widget 
                                    publicKey={UPLOAD_PUBLIC_KEY}
                                    tabs='file'
                                    customTabs={{preview: uploadcareTabEffects}}
                                    previewStep='true'
                                    imagesOnly='true'
                                    multiple='true'
                                    multipleMax='3'
                                    effects='crop'
                                    multipartPartSize='10480'
                                    onChange={(file) => setImageValue(file)}
                                    crop='free, 16:9, 4:3, 5:4, 1:1'
                                    value={props.images}
                                />
                               
                            </div>
                        </div>
                        
                    </div>

                    <div className="modal-footer py-1">
                        <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                        <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                            {submit}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

        // <div className="modal fade" id="propertyUpdateModal" tabIndex="-1" role="dialog" aria-labelledby="propertyUpdateModal" aria-hidden="true">
        //     <div className="modal-dialog" role="document">
        //         <div className="modal-content border-0">
        //             <form onSubmit={handleUpdate}>
        //                 <div className="modal-header py-2 text-white bg-dark">
        //                     <h5 className="modal-title" id="exampleModalLongTitle">Update property</h5>
        //                 </div>
        
        //                 <div className="modal-body py-3">
        //                 <span className="text-danger small"><b>{error}</b></span>
        //                 <span className="text-success small"><b>{success}</b></span>

        //                 <input type="hidden" name='id' defaultValue={props.propertyId} />

        //                     <div className="row py-2">
        //                         <div className="col-md-6">
        //                             <label htmlFor="titleInput" className='font-weight-medium'>State: <span className='text-danger'>*</span></label>
        //                             <input type="text" defaultValue={props.name} id='titleInput' name='name' className='form-control form-control-sm'  placeholder='State name' required />
        //                         </div>
        //                         <div className="col-md-6"></div>
        //                     </div>

        //                     <div className="row py-2">
        //                         <div className="col-md-6">
        //                             <label htmlFor="iconInput" className='font-weight-medium'>Country: <span className='text-danger'></span></label>
        //                             <select name="country" id="" className='form-control form-control-sm'>
        //                                 <option value=""> None </option>
        //                                 {options}
        //                             </select>
        //                         </div>
        //                         <div className="col-md-6"></div>
        //                     </div>
        //                 </div>

        //                 <div className="modal-footer py-1">
        //                     <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
        //                     <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
        //                         {submit}
        //                     </button>
        //                 </div>
        //             </form>
        //         </div>
        //     </div>
        // </div>
      );
    }

export default Update;