import Create from "./Create";
import Stats from "./Stats";
import Table from "./Table";

function Booking() {
  return (
    <div>
        <Stats />
        <Table />
    </div>
  );
}

export default Booking;
