import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../Apis/axios";


const url = '/auth/forgot';

function ForgotPassword(){

    const [emailError, setEmailError] = useState();
    const [success, setSuccess] = useState("");
    const [submit, setSubmit] = useState("Continue");

    async function handleForgotPassword(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)
        const inputValues = { email: event.target.elements.emailInput.value }; 
        try{
            // Axios API.
            const response = await axiosPrivate.post(url, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
                
                setEmailError("");
                event.target.elements.submitBtn.disabled = false;
                event.target.elements.emailInput.value = "";
                setSubmit("Continue");
                setSuccess(response?.data?.message);
                
            }catch(e){
                setSubmit("Continue");
                event.target.elements.submitBtn.disabled = false;
                if (e.response.data.error.hasOwnProperty("email")){
                    setEmailError(e.response.data.error.email);
                }else {
                    console.error(e);
                }   
        }
    }

    return(
        <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                    <img src="../../images/hb-name-logo.png" alt="logo" />
                </div>
                <h4>Reset Password</h4>
                <br/>
                <span className="text-success small"><b>{success}</b></span>
                
                <form className="pt-3" onSubmit={handleForgotPassword}>
                    <div className="form-group">
                         <input type="email" name="emailInput" className="form-control form-control-sm" placeholder="Email" required/>
                         <span className="text-danger small"><b>{emailError}</b></span>
                    </div>
                    <div className="mt-3">
                        <button type="submit" name="submitBtn" className="btn btn-block btn-danger btn-md font-weight-medium">
                            {submit}
                        </button>
                    </div>

                    <div className="mt-4">
                        Back to <Link to="/login" className="text-danger">Login</Link>.
                    </div>
                </form>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;