/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../../Apis/axios';
import { Widget } from '@uploadcare/react-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';
import useAuth from '../../../Hooks/useAuth';
import Select from "react-select";

const UPLOAD_PUBLIC_KEY = "d56c8b8e1e84d37ee09a";

function Update(props) {
    const url = "/rooms";
    const { auth } = useAuth();
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Update");
    const [imageValue, setImageValue] = useState("");
    const [properties, setProperties] = useState();
    const [propValue, setPropValue] = useState(null);
    const [statusOpts, setStatusOpts] = useState();



    if (props.roomData[11] == 'active'){
        setTimeout(() => setStatusOpts(<><option value="active">Active</option><option value="inactive">Inactive</option><option value="pending">Pending</option></>), 100);
    }else if(props.roomData[11] == 'inactive'){
        setTimeout(() => setStatusOpts(<><option value="inactive">Inactive</option><option value="pending">Pending</option><option value="active">Active</option></>), 100);
    }else{
        setTimeout(() => setStatusOpts(<><option value="pending">Pending</option><option value="inactive">Inactive</option><option value="active">Active</option></>), 100);
    }
    
    
    async function getProperties(){
        try{
            const propertiesResponse =  await axiosPrivate.get("/properties");
            // Print out responses
            const propertyList = propertiesResponse?.data.map((item) => { return { value: item.id, label: item.name} });
            setProperties(propertyList);
        }catch(e){
            console.error(e);
        }
    }

    useEffect(() => {
        getProperties();
    }, []);

    useEffect(() => {
        setTimeout(() => setPropValue(props.roomProperty), 100);
    }, [props]);
    

    async function handleUpdate(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)

        const inputValues = {
            id: event.target.elements.id.value,
            propertyId: event.target.elements.property.value,
            roomType: event.target.elements.roomName.value,
            status: event.target.elements.status.value,
            beds: event.target.elements.beds.value,
            adults: event.target.elements.adults.value,
            children: event.target.elements.children.value,
            limit: event.target.elements.limit.value,
            price: event.target.elements.price.value,
            pricePerHour: event.target.elements.priceHr.value,
            discount: event.target.elements.discount.value,
        }    
        
        try{
            // Axios API.
            const response = await axiosPrivate.put(url+"/"+inputValues.id, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
                
                if ((response.status === 200) && (imageValue != "")){
                    
                    const uploadsInputValues = {
                        id: event.target.elements.imageId.value,
                        userId: auth.userId,
                        typeId: inputValues.id,
                        type: 'room',
                        format: 'image/url',
                        url: imageValue.cdnUrl,
                    }  
                    if(uploadsInputValues.id != ""){
                        await axiosPrivate.put("/uploads/"+uploadsInputValues.id, JSON.stringify(uploadsInputValues));
                    }else{
                        await axiosPrivate.post("/uploads/", JSON.stringify(uploadsInputValues));
                    }
                    // await axiosPrivate.put("/uploads/"+uploadsInputValues.id, JSON.stringify(uploadsInputValues));
                }
                
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");
            // Print Out Responses.
            setSuccess("Room updated successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }

  return (
    <div className="modal fade" id="roomUpdateModal" tabIndex="-1" role="dialog" aria-labelledby="#roomUpdateModal" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content border-0">
                <form onSubmit={handleUpdate}>
                    <div className="modal-header py-2 text-white bg-dark">
                        <h5 className="modal-title" id="exampleModalLongTitle">Update Room</h5>
                    </div>
    
                    <div className="modal-body py-3">
                    <span className="text-danger small"><b>{error}</b></span>
                    <span className="text-success small"><b>{success}</b></span>

                    <input type="hidden" name='id' defaultValue={props.roomId} />
                    <input type="hidden" name='imageId' defaultValue={props.imageId} />

                        <div className="row py-3">

                            <div className="col-md-3 px-2">
                                <label htmlFor="propertyInput" className='font-weight-medium'>Property Name: <span className='text-danger'>*</span></label>
                                <Select 
                                name="property" 
                                options={ properties } 
                                value={propValue}
                                onChange={(e) => setPropValue(e)}
                                />
                            </div>
                            
                            <div className="col-md-5 px-2">
                                <label htmlFor="nameInput" className='font-weight-medium'>Room Name: <span className='text-danger'>*</span></label>
                                <input type="text" id='nameInput' name='roomName' defaultValue={props.roomData[2]} className='form-control form-control-sm'  placeholder='Name' required />
                            </div>
                        </div>

                        <div className="row py-3">
                            
                            <div className="col-md-3 px-2">
                                <label htmlFor="status" className='font-weight-medium'>Status: <span className='text-danger'>*</span></label>
                                <select name="status" id="status" className='form-control form-control-sm'>
                                    {statusOpts}
                                </select>
                            </div>
                            
                            <div className="col-md-2 px-2">
                                <label htmlFor="beds" className='font-weight-medium'>Beds: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="beds" defaultValue={props.roomData[3]} min={0} id="beds" placeholder="beds" required />
                            </div>

                            <div className="col-md-2 px-2">
                                <label htmlFor="adults" className='font-weight-medium'>Adults: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="adults" defaultValue={props.roomData[4]} min={0} id="adults" placeholder="adults" required />
                            </div>

                            <div className="col-md-2 px-2">
                                <label htmlFor="children" className='font-weight-medium'>Children: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="children" defaultValue={props.roomData[5]} min={0} id="children" placeholder="children" required />
                            </div>
                            
                            <div className="col-md-2 px-2">
                                <label htmlFor="quantity" className='font-weight-medium'>Quantity: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="limit" defaultValue={props.roomData[6]} min={0} id="quantity" placeholder="quantity" required />
                            </div>

                        </div>
                       
                        <div className="row py-3">

                            <div className="col px-2">
                                <label htmlFor="price" className='font-weight-medium'>Price: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="price" defaultValue={props.roomData[13]?.price} min={0} id="price" placeholder="price" required />
                            </div>

                            <div className="col px-2">
                                <label htmlFor="price-hr" className='font-weight-medium'>Price/Hr: <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="priceHr" min={0} defaultValue={props.roomData[13]?.pricePerHour} id="price-hr" placeholder="Price/Hr" required />
                            </div>

                            <div className="col px-2">
                                <label htmlFor="discount" className='font-weight-medium'>Discount (%): <span className='text-danger'>*</span></label>
                                <input type="number" className="form-control form-control-sm" name="discount" defaultValue={props.roomData[13]?.discount} min={0} id="discount" placeholder="Discount (%)" required />
                            </div>

                            <div className="col-md-4 px-2">
                                <label htmlFor="images" className='font-weight-medium'>Room Images: <span className='text-danger'></span></label>
                                <br />
                                <Widget 
                                    publicKey={UPLOAD_PUBLIC_KEY}
                                    tabs='file'
                                    customTabs={{preview: uploadcareTabEffects}}
                                    previewStep='true'
                                    imagesOnly='true'
                                    multiple='true'
                                    multipleMax='3'
                                    effects='crop'
                                    multipartPartSize='10480'
                                    onChange={(file) => setImageValue(file)}
                                    crop='free, 16:9, 4:3, 5:4, 1:1'
                                    value={props.images}
                                    required
                                />
                            </div>

                        </div>
                        
                    </div>

                    <div className="modal-footer py-1">
                        <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                        <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                            {submit}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
}

export default Update;