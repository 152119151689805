import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../../Apis/axios";
import useAuth from "../../Hooks/useAuth";

const url = "/auth/sign-out";
function Header() {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const [bodyClass, setBodyClass] = useState("");

    async function logout(){
        // add axios logout endpoint
        try{
            // Axios API.
            const response = await axios.post(url, 
                JSON.stringify({}), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });

                console.log(response.data);
                
                setAuth({});
                // navigate("/");
                window.location.reload(false);
                
        }catch(e){
            console.error(e);
        }
    }

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <Link className="navbar-brand brand-logo mr-5" to="/dashboard"><img src="../../images/hb-name-logo.png" className="mr-2" alt="logo"/></Link>
            <Link className="navbar-brand brand-logo-mini text-danger" to="/dashboard">HB</Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <button className="navbar-toggler navbar-toggler align-self-center" onClick={ () => { document.querySelector("body").classList.toggle("sidebar-icon-only")}} type="button" data-toggle="minimize">
            <span className="icon-menu"></span>
            </button>
            
            <ul className="navbar-nav navbar-nav-right">
  
            <li className="nav-item nav-profile dropdown">
                <Link className="nav-link text-primary fs-6 dropdown-toggle" to="#" data-toggle="dropdown" id="profileDropdown">
                    My Account
                </Link>
                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                    <button className="dropdown-item" onClick={logout}>
                        <i className="ti-power-off text-primary"></i>
                        Logout
                    </button>
                </div>
            </li>
            
            </ul>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={ () => { document.querySelector("#sidebar").classList.toggle("active")}} data-toggle="offcanvas">
                <span className="icon-menu"></span>
            </button>
        </div>
    </nav>
  );
}

export default Header;
