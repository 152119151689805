import React, { useEffect, useState } from 'react'
import axios, { axiosPrivate } from '../../../Apis/axios';
import { Widget } from '@uploadcare/react-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';
import useAuth from '../../../Hooks/useAuth';

function Create(props) {

    const UPLOAD_PUBLIC_KEY = "d56c8b8e1e84d37ee09a";

    const url = "/users";
    const [error, setError] = useState();  
    const { auth } = useAuth();
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Create");
    const [imageValue, setImageValue] = useState("");

    const [countries, setCountries] = useState();
    let [states, setStates] = useState();
    const [roles, setRoles] = useState();
    

    async function handleCreate(event){
        event.preventDefault();
        
        const inputValues = {
            firstName: event.target.elements.firstName.value,
            middleName: event.target.elements.middleName.value,
            lastName: event.target.elements.lastName.value,
            email: event.target.elements.email.value,
            telephone: event.target.elements.telephone.value,
            stateId: event.target.elements.state.value,
            address: event.target.elements.address.value,
            roleId: event.target.elements.role.value,
            avatar: imageValue.cdnUrl,
            password: event.target.elements.password.value,
            confirmPassword: event.target.elements.confirmPassword.value
        }
        
        if(inputValues.password !== inputValues.confirmPassword) {
            event.target.elements.confirmPassword.setCustomValidity("Passwords don't match");
        } else {
            event.target.elements.confirmPassword.setCustomValidity('');
        }
        
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)

        try{
            console.log(inputValues);
            // Axios API.
            const response = await axios.post(url, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            console.log(response);

            event.target.elements.submitBtn.disabled = false;
            setSubmit("Create");

            // Print Out Responses.
            console.log(response?.data);
            setSuccess("User created successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Create");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }


    async function getRoles(){
        try{
          const countriesResponse =  await axiosPrivate.get("/roles");
          // Print out responses
          const roleList = countriesResponse?.data.map((item) => {
              return(
                <option key={item.id} value={item.id}>
                {item.title}
              </option>
            )
          });
        setRoles(roleList);
        //console.log(countriesResponse?.data);
        }catch(e){
          console.error(e);
        }
      }


    async function getCountries(){
        try{
          const countriesResponse =  await axiosPrivate.get("/countries");
          // Print out responses
          const countryList = countriesResponse?.data.map((item) => {
              return(
                <option key={item.id} value={item.id}>
                {item.name}
              </option>
            )
          });
        setCountries(countryList);
        //console.log(countriesResponse?.data);
        }catch(e){
          console.error(e);
        }
      }
    
      async function getStates(event){
        const countryId = event.target.value
        console.log(typeof countryId);
        if (countryId > 0) {
          try{
            const statesResponse = await axiosPrivate.get("/states/country/"+countryId);
            //console.log(statesResponse?.data);
            const stateList = statesResponse?.data.map((item) => {
              return(
                <option key={item.id} value={item.id}>
                {item.name}
              </option>
            )
          });
          setStates(stateList);
    
          }catch(e){
            console.error(e);
          }
        }else{
          setStates("");
        }
      }
    
      useEffect(()=>{
        getCountries();
        getRoles();
      }, []);

  return (
    <div className="modal fade" id="userModal" tabIndex="-1" role="dialog" aria-labelledby="#userModal" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content border-0">
                    <form onSubmit={handleCreate}>
                        <div className="modal-header py-2 text-white bg-dark">
                            <h5 className="modal-title" id="exampleModalLongTitle">Create User</h5>
                        </div>
        
                        <div className="modal-body py-3">
                        <span className="text-danger small"><b>{error}</b></span>
                        <span className="text-success small"><b>{success}</b></span>

                            <div className="row py-2">
                                <div className="col-md-4">
                                    <label className='font-weight-medium'>First Name: <span className='text-danger'>*</span></label>
                                    <input type="text" name='firstName' defaultValue={props.userData} className='form-control form-control-sm'  placeholder='First name' required />
                                </div>
                                <div className="col-md-4">
                                    <label className='font-weight-medium'>Middle Name: <span className='text-danger'></span></label>
                                    <input type="text" name='middleName' className='form-control form-control-sm'  placeholder='Middle name' />
                                </div>
                                <div className="col-md-4">
                                    <label className='font-weight-medium'>Last Name: <span className='text-danger'>*</span></label>
                                    <input type="text" name='lastName' className='form-control form-control-sm'  placeholder='Last name' required />
                                </div>
                            </div>
    
                            <div className="row py-2">
                                <div className="col-md-4">
                                    <label className='font-weight-medium'>Telephone: <span className='text-danger'></span></label>
                                    <input type="text" name='telephone' className='form-control form-control-sm'  placeholder='Telephone NO' />
                                </div>
                                <div className="col-md-6">
                                    <label className='font-weight-medium'>Email: <span className='text-danger'>*</span></label>
                                    <input type="email" name='email' className='form-control form-control-sm'  placeholder='Email' required />
                                </div>
                                <div className="col-md-6"></div>
                            </div>
                            <div className="row py-2">
                                <div className="col-md-3">
                                    <label className='font-weight-medium'>Country: <span className='text-danger'>*</span></label>
                                    <select onChange={getStates} name="country" className='form-control form-control-sm' required>
                                        <option value=""> None </option>
                                        {countries}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className='font-weight-medium'>State: <span className='text-danger'>*</span></label>
                                    <select name="state" className='form-control form-control-sm' required>
                                        <option value=""> None </option>
                                        {states}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className='font-weight-medium'>Address: <span className='text-danger'>*</span></label>
                                    <input type="text" name='address' className='form-control form-control-sm'  placeholder='Address' required />
                                </div>
                            </div>

                            <div className="row py-2">
                                <div className="col-md-3">
                                    <label className='font-weight-medium'>Status: <span className='text-danger'>*</span></label>
                                    <select name="status" className='form-control form-control-sm' required>
                                        <option value="pending">Pending </option>
                                        <option value="active">Active </option>
                                        <option value="inactive">Inactive </option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className='font-weight-medium'>Role: <span className='text-danger'>*</span></label>
                                    <select name="role" className='form-control form-control-sm' required>
                                        <option value=""> None </option>
                                        {roles}
                                    </select>
                                </div>
                                <div className="col-md-6"></div>
                            </div>
                            
                            <div className="row py-2">
                                <div className="col-md-6">
                                    <label className='font-weight-medium'>Avatar: <span className='text-danger'></span></label>
                                    <br />
                                    <Widget 
                                        publicKey={UPLOAD_PUBLIC_KEY}
                                        tabs='file'
                                        customTabs={{preview: uploadcareTabEffects}}
                                        previewStep='true'
                                        imagesOnly='true'
                                        multiple='true'
                                        multipleMax='1'
                                        effects='crop'
                                        multipartPartSize='10480'
                                        onChange={(file) => setImageValue(file)}
                                        crop='free, 16:9, 4:3, 5:4, 1:1'
                                        required
                                    />
                                </div>
                                <div className="col-md-6"></div>
                            </div>
    
                            <div className="row py-2">
                                <div className="col-md-4">
                                    <label htmlFor="descInput" className='font-weight-medium'>Password: </label>
                                    <input 
                                        type="password" 
                                        name="password" 
                                        id="passwordInput" 
                                        className='form-control form-control-sm' 
                                        pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}"
                                        title="min: 8 characters, max: 24 characters, special character, uppercase character and number." 
                                        required />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="descInput" className='font-weight-medium'>Confirm Password: </label>
                                    <input type="password" name="confirmPassword" id="confirmPasswordInput" className='form-control form-control-sm' required />
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                        </div>

                        <div className="modal-footer py-1">
                            <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                            <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                                {submit}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
  );
}

export default Create;
