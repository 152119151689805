
function Stats() {
  return (
    <>
        <div className="row">
            <div className="col-md-3 mb-4 stretch-card transparent">
                <div className="card card-tale">
                <div className="card-body">
                    <p className="mb-4">{}</p>
                    <p className="fs-30 mb-2">{}</p>
                    <p>{}</p>
                </div>
                </div>
            </div>
            <div className="col-md-3 mb-4 stretch-card transparent">
                <div className="card card-dark-blue">
                <div className="card-body">
                    <p className="mb-4">{}</p>
                    <p className="fs-30 mb-2">{}</p>
                    <p>{}</p>
                </div>
                </div>
            </div>
            
            <div className="col-md-3 mb-4 stretch-card transparent">
                <div className="card card-light-blue">
                <div className="card-body">
                    <p className="mb-4">{}</p>
                    <p className="fs-30 mb-2">{}</p>
                    <p>{}</p>
                </div>
                </div>
            </div>
            <div className="col-md-3 mb-4 stretch-card transparent">
                <div className="card card-light-danger">
                <div className="card-body">
                    <p className="mb-4">{}</p>
                    <p className="fs-30 mb-2">{}</p>
                    <p>{}</p>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Stats