/* eslint-disable eqeqeq */
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "../../Apis/axios";
import useAuth from "../../Hooks/useAuth";
// import { useCookies } from "react-cookie";


const url = '/auth/sign-in';

function Login(){

    // variable declarations.
    // const [cookies, setCookie] = useCookies(["jw"]);

    const [emailError, setEmailError] = useState();
    const [passwordError, setPasswordError] = useState();
    const navigate = useNavigate();  
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";
    const { setAuth } = useAuth();
    const [submit, setSubmit] = useState("Sign In");

    const [showPass, setShowPass] = useState(false);
    const [passIcon, setPassIcon] = useState("icon-lock");
    const [passType, setPassType] = useState("password");

    function handlePassView(){
        if (showPass){
            setShowPass(false);
            setPassIcon("icon-lock");
            setPassType("password");
        }else{
            setShowPass(true);
            setPassIcon("icon-eye");
            setPassType("text")
        }
    }

    async function handleLogin(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)

        const inputValues = {
            email: event.target.elements.emailInput.value,
            password: event.target.elements.passwordInput.value
        }

        try{
            // Axios API.
            const response = await axios.post(url, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            event.target.elements.submitBtn.disabled = false;
            setEmailError("");
            setPasswordError("");
            setSubmit("Sign In");

            if(response?.data?.roleId > 2){
                
                await axios.post("/auth/sign-out", 
                    JSON.stringify({}), 
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                );

                throw new Error("Invalid user.");
            }
            
            // Print Out Responses.
            // console.log(response?.data);

            // Set Authentication.
            setAuth(response?.data);
            
            // Redirect To Dashboard.
            navigate(from, { replace: true });

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Sign In");
            if (e.response.data.error.hasOwnProperty("email")){
                setEmailError(e.response.data.error.email);
                setPasswordError("");
            }else if (e.response.data.error.hasOwnProperty("password")){
                setEmailError("");
                setPasswordError(e.response.data.error.password);
            }else if (e?.message == "Invalid user.") {
                setEmailError("User doesn't exist.")
            }else {
                console.error(e);
            }   
        }
    }

    return(
        <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                    <img src="../../images/hb-name-logo.png" alt="logo" />
                </div>
                <h4>Hello! let's get started</h4>
                <h6 className="font-weight-light">Sign in to continue.</h6>
                <form className="pt-3" onSubmit={handleLogin}>
                    <div className="form-group">
                        <input type="email" name="emailInput" className="form-control form-control-sm" placeholder="Email" required/>
                    <span className="text-danger small"><b>{emailError}</b></span>
                    </div>
                    <div className="form-group">
                        <div class="input-group flex-nowrap">
                            <input type={passType} name="passwordInput"  className="form-control placeholder-text border-0 border-bottom" placeholder="Password" required />
                            <span class="input-group-text bg-white border-top-1 border-end-0" onClick={()=>{ handlePassView() }} id="addon-wrapping"><i className={passIcon}></i></span>
                            <span className="text-danger small"><b>{passwordError}</b></span>
                        </div>
                        {/* <input type="password" name="passwordInput" className="form-control form-control-sm" placeholder="Password" required/> */}
                    <span className="text-danger small"><b>{passwordError}</b></span>
                    </div>
                    <div className="mt-3">
                        <button type="submit" name="submitBtn" className="btn btn-block btn-danger btn-md font-weight-medium">
                            {submit}
                        </button>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="pl-4">
                        <label className="form-check-label">
                            <input type="checkbox" className="form-check-input"/>
                            Keep me signed in.
                        </label>
                    </div>
                        <Link to="/forgot" className="auth-link text-black">Forgot password?</Link>
                    </div>
                    <div className="text-center mt-4 font-weight-light">
                        Don't have an account? <Link to="/register" className="text-danger">Create</Link>
                    </div>
                </form>
                </div>
            </div>
        </div>
    );
}

export default Login;