/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../../Apis/axios';
import useAuth from '../../../Hooks/useAuth';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



function Create(props) {
    const url = "/bookings";
    const { auth } = useAuth();
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Create");
    const [propertyOptions, setPropertyOptions] = useState();
    const [rooms, setRooms] = useState();
    const [roomValue, setRoomValue] = useState();
    const [organisations, setOrganisations] = useState();
    const [paymentMethods, setPaymentMethods] = useState();
    const [maxLimit, setMaxLimit] = useState(0);
    const [maxQuantity, setMaxQuantity] = useState(0);
    const [excludedDates, setExcludedDates] = useState([]);
    const [dateInputClass, setDateInputClass] = useState('form-control form-control-sm');
    const dateBoxClass = 'd-none';
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [validationArr, setValidationArr] = useState([]);

    useEffect(() => {
        let opt = [];

        if(props.hasOwnProperty("propertyOpt")) opt = props.propertyOpt; 
        if(opt){
            // console.log(opt)
            const optionsArray = opt.map((item, index)=> { return {value: index, label: item.name} });
            setPropertyOptions(optionsArray);
        }

        const org = props.organisations;
        if (org){
            const orgArray = org.map((item) => { return {value: item.id, label:  item.name}});
            setOrganisations(orgArray);
        }

        const pay = props.payments;
        if (pay){
            const payArray = pay.map((item) => { return {value: item.id, label:  item.title}});
            setPaymentMethods(payArray);
        }
    }, [props])
    
    function getRooms(event){
        setInputList([dynamicFields])
        const propertyId = event.value
        if (props.propertyOpt[propertyId].Rooms.length > 0) {
            const roomsObj = props.propertyOpt[propertyId].Rooms.map((item) => {
                return {value: item.id,  label: item.roomType, limit: item.limit}
            })
            setRooms(roomsObj);
            setMaxLimit(roomsObj.length - 1);
        }else{
          setRooms("");
        }
    }


    const getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};


    async function handleCreate(event){
        event.preventDefault();
        const orderIdValue = Date.now();

        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)
        
        const staticInputValues = {
            userId: auth.userId, 
            organisationId: event.target.organisation.value,
            paymentId: event.target.paymentMethod.value,
            reference: event.target.reference.value,
            type: "Corporate",
            status: "pending",
        };

        const bookingProcessResult = inputList.map(async (item) => {
            const dateArray = item.arrivalDeparture.split(" - ");
            const inputValues = {
                ...staticInputValues,
                roomId: item.roomType.value,
                orderId: orderIdValue,
                price: item.price,
                commission: item.commission,
                markup: item.markup,
                totalAmount: ((parseInt(item.price) + parseInt(item.commission) + parseInt(item.markup))*(parseInt(item.quantity))),
                arrivalDate: dateArray[0].split("/").reverse().join("-"),
                departureDate: dateArray[1].split("/").reverse().join("-"),
                quantity: item.quantity,
            }

            try{

                const list = getDaysArray(new Date(inputValues.arrivalDate), new Date(inputValues.departureDate));

                const response = await axiosPrivate.post(url, 
                    JSON.stringify(inputValues), 
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                });
                   
                if (response.status === 200){

                    list.map(async (item) => {

                        const dailyInput = {
                            roomId: inputValues.roomId,
                            date: item.toISOString().slice(0,10),
                            quantity: inputValues.quantity
                        }
    
                        try{    
                            await axiosPrivate.post("/bookings/daily", 
                                JSON.stringify(dailyInput), 
                                {
                                    headers: { 'Content-Type': 'application/json' },
                                    withCredentials: true
                            });
                        }catch(error){
                            console.log(error);
                        }
                    });
                    // update transactions
                    console.log(response.data.data)
                    const transactionInput = {
                        userId: auth.userId,
                        orderId: orderIdValue,
                        paymentId: inputValues.paymentId,
                        reference: inputValues.reference,
                        status: inputValues.status,
                        amount: inputValues.totalAmount,
                    }
                    await axiosPrivate.post("/transactions", 
                        JSON.stringify(transactionInput), 
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                    });

                    return "success";
                }else{
                    console.log(response)
                }
                    
                // console.log(response?.data);
                // Print Out Responses.

                //setSuccess("Booking created successfully.");
                //window.location.reload(false);

            }catch(error){
                console.log(error);
                return "error";
            }

        });

        event.target.elements.submitBtn.disabled = false;
        setSubmit("Create");

        const result = await bookingProcessResult[0];
        if(result == "success"){
            setError("");
            setSuccess("Booking created successfully.");
            window.location.reload(false);
        }else {
            setSuccess("");
            setError("An Error occured during your booking.");
        }

    }



    
    

   const dynamicFields = {roomType:"", quantity:"", price:"", commission:"", markup:"", arrivalDeparture:"", start: dateRange[0], end: dateRange[1], roomVal: {} };
   const [inputList, setInputList] = useState([dynamicFields]);

  // handle input change
  const handleInputChange = (e, index) => {
    // e.preventDefault();
    let name = "";
    let value = "";
    if(!e.target){
        if (e.label){
            name = "roomType";
            value = e;
        }else{

            let start = new Date(e[0]).toLocaleDateString().slice(0,10);
            let end = new Date(e[1]).toLocaleDateString().slice(0,10);
            const arrayValue = start +" - "+ end;
            name = "arrivalDeparture";
            value = arrayValue;

        }
    }else{
        name = e?.target.name;
        value = e?.target.value;
    }
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    setMaxLimit(maxLimit + 1);
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    e.preventDefault();
    if((maxLimit > 0) ){
        setMaxLimit(maxLimit - 1);
        setInputList([...inputList, dynamicFields]);
    }
  };


    async function getUnavailableDates(e){
        try{
            // Axios API.
            setMaxQuantity(e.limit);
            setRoomValue(e.value);
            const response = await axiosPrivate.post("/bookings/unavailable/", 
                JSON.stringify({ 
                    roomId: e.value,
                    limit: e.limit 
                }));

                if(response.status === 200 ){
                    const exDates = response.data.map(item => new Date(item.date) );
                    console.log(exDates);
                    setExcludedDates(exDates);
                }
        }catch(e){
            console.log(e);
        }

    }

    function validateDateRange(e, index){
        setDateInputClass("form-control form-control-sm")
        
        const dateInput = document.getElementById('dateDisplay'+index);
        // console.log(e);
        if(e[1] !== null){

            const to = new Date(e[1]);
            const from = new Date(e[0]);

            const checkDates = excludedDates.map((item) => {
                if((item.getTime() <= to.getTime() && item.getTime() >= from.getTime())){
                    return item;
                }      
                return false;
            });

            if (checkDates[0]) {
                dateInput.classList.add("is-invalid"); setDateInputClass(dateInput.className); 
                // console.log(dateInput.value);
                function addItem(array, item) {
                    if (!array.includes(item)){
                        array.push(item);
                    }
                    return array;
                }
                setValidationArr(addItem(validationArr, index));
                // setValidationArr(validationArr, ...index);
                console.log(validationArr)
            } else {
                function removeItem(array, item) {
                    for(let i = 0; i<array.length; i++){
                        if(array[i] == item) {
                           array.splice(array.indexOf(item), 1);
                        }
                    }
                    return array;
                    
                }
                dateInput.classList.remove("is-invalid"); setDateInputClass(dateInput.className); //submitBtn.disabled = false;
                setValidationArr(removeItem(validationArr, index));
                console.log(validationArr)
            }
        }
    }

    async function updateMaxQuantity(e, index){
            
        if(e[1] !== null){
            const end = new Date(e[1]);
            const start = new Date(e[0]);

            try{
                const response = await axiosPrivate.post("/bookings/available/", 
                JSON.stringify({ 
                    start: start,
                    end: end,
                    roomId: roomValue,
                    limit: maxQuantity
                }));
                
                if (response.status === 200 ){
                    //console.log("herer now "+response.data[0].quantity);
                    const maxInput = document.getElementById('max'+index);
                    if(response.data[0]?.quantity){
                        maxInput.max = (maxQuantity - response.data[0].quantity);
                    }else{
                        maxInput.max = maxQuantity;
                    }
                }

            }catch(err){
                console.log(err);
            }
        }
    }

    const handleClick = (e, index) => {
        e.preventDefault();
        const dateInput = document.getElementById("datePicker"+index);
        dateInput.classList.toggle("d-none");
    }

   

  return (
    <div className="modal fade" id="bookingModal" tabIndex="-1" role="dialog" aria-labelledby="#bookingModal" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content border-0">
                <form onSubmit={handleCreate}>
                    <div className="modal-header py-2 text-white bg-dark">
                        <h5 className="modal-title" id="exampleModalLongTitle">Create Corporate Booking</h5>
                    </div>
    
                    <div className="modal-body py-3">
                    <span className="text-danger small"><b>{error}</b></span>
                    <span className="text-success small"><b>{success}</b></span>

                        <div className="row py-2">
                            <div className="col-md-4 px-2">
                                <label htmlFor="organisationInput" className='font-weight-medium'>Corporate Client: <span className='text-danger'>*</span></label>
                                    <Select name="organisation" options={organisations} required />
                                <a className='small text-dark' href="/clients"><b> - Add New Corporate Client</b></a>
                            </div>

                            <div className="col-md-5 px-2">
                                <label htmlFor="nameInput" className='font-weight-medium'>Property: <span className='text-danger'>*</span></label>
                                <Select name="property" options={propertyOptions} onChange={(e) => getRooms(e)} />
                            </div>
                        </div>
                        
                        

                        <br />

                        <h4 className="text-danger p-0">Rooms</h4>
                  {
                      inputList.map((data, index)=>{
                          return(
                            <div key={index}>
                                <hr className="" />
                                <div className="row px-3 py-2">
                                    <div className="col-md-4 px-2">
                                        <label htmlFor="typeInput" className='font-weight-medium'>Room: <span className='text-danger'>*</span></label>
                                        <Select name="roomType" options={rooms} onChange={(e) => { handleInputChange(e, index); getUnavailableDates(e);}} />
                                    </div>
                                    
                                    <div className="col-md-4 px-2">
                                        <label htmlFor="arrivalInput" className='font-weight-medium'>Date: <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="text" id={"dateDisplay"+index} className='form-control form-control-sm' value={data.arrivalDeparture} onChange={() => this.reportValidity()} required  readOnly/>
                                            <div className="input-group-append">
                                                <button className="btn btn-sm btn-dark" id={"button"+index} onClick={(e) => { console.log(data.roomType); getUnavailableDates(data.roomType); handleClick(e, index); }}><i className="ti-calendar"></i></button>
                                            </div>
                                        </div>

                                        <div id={"datePicker"+index} className={dateBoxClass}>

                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                className={dateInputClass}
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update) => { 
                                                    handleInputChange(update, index);
                                                    updateMaxQuantity(update, index);
                                                    validateDateRange(update, index);
                                                    setDateRange(update);
                                                }}
                                                minDate={new Date()}
                                                excludeDates={excludedDates}
                                                selectsDisabledDaysInRange={false}
                                                inline
                                            />
                                        </div>    
                                    </div>
     
                                </div>
                                <div className="row px-3 py-2">
                                    <div className="col px-2">
                                        <label htmlFor="priceInput" className='font-weight-medium'>Price: <span className='text-danger'>*</span></label>
                                        <div className="input-group input-group-sm has-validation">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-dark text-white">₦</span>
                                            </div>
                                            <input type="number" min={0} value={data.price} onChange={e => handleInputChange(e, index)} className="form-control" name="price" id="priceInput" required placeholder="Price" />
                                        </div>
                                    </div>
                                    <div className="col px-2">
                                        <label htmlFor="commisssionInput" className='font-weight-medium'>Commission: <span className='text-danger'>*</span></label>
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-dark text-white">₦</span>
                                            </div>
                                            <input type="number" min={0} value={data.commission} onChange={e => handleInputChange(e, index)} className="form-control" name="commission" id="commisssionInput" required placeholder="Commission" />
                                        </div>
                                    </div>
                                    <div className="col px-2">
                                        <label htmlFor="markupInput" className='font-weight-medium'>Markup: <span className='text-danger'>*</span></label>
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-dark text-white">₦</span>
                                            </div>
                                            <input type="number" min={0} value={data.markup} onChange={e => handleInputChange(e, index)} className="form-control" name="markup" id="markupInput" required placeholder="Markup" />
                                        </div>
                                    </div>
                                    <div className="col-md-2 px-2">
                                        <label htmlFor="qtyInput" className='font-weight-medium'>Quantity: <span className='text-danger'>*</span></label>
                                        <input type="number"  min={0} id={"max"+index} max=""  onChange={e => handleInputChange(e, index)} className='form-control form-control-sm' onKeyDown={(e) => {e.preventDefault(); e.stopPropagation();}} name="quantity" required  placeholder="Quantity"  />
                                        
                                    </div>
                                    <div className="col btn-box mt-auto">
                                    {inputList.length !== 1 && <button
                                        className="btn btn-sm btn-danger"
                                        onClick={(e) => handleRemoveClick(e, index)}>-</button>} &nbsp; 
                                    {inputList.length - 1 === index && <button className="btn btn-sm btn-dark" onClick={e => handleAddClick(e)}>Add</button>}
                                    </div>
                                </div>
                            </div>
                          )
                      })
                  }

                                        
                    <br />

                    <h4 className="text-danger p-0">Payment</h4>
                    <hr />
                    <div className="row py-2">
                        <div className="col-md-4 px-2">
                            <label htmlFor="organisationInput" className='font-weight-medium'>Payment Method: <span className='text-danger'>*</span></label>
                            <Select name="paymentMethod" options={paymentMethods}  />
                        </div>

                        <div className="col-md-5 px-2">
                            <label htmlFor="nameInput" className='font-weight-medium'>Reference NO: <span className='text-danger'>*</span></label>
                            <input type="text" name="reference" className='form-control form-control-sm' />
                        </div>
                    </div>

                </div>

                    {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                       
                    <div className="modal-footer py-1">
                        <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                        <button type="submit" id='createBtn' name="submitBtn" className="btn btn-danger btn-sm">
                            {submit}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
}

export default Create;