import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useAuth from '../../Hooks/useAuth';

function Authentication() {

  const { auth } = useAuth();
  const navigate = useNavigate();

  console.log(auth?.accessToken);

    useEffect(()=>{
      if(auth?.accessToken){
        navigate("/dashboard");
      } 
    }, [auth?.accessToken, navigate]);

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
                <Outlet/>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Authentication;
