import { useEffect, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import useAuth from "../../../Hooks/useAuth";

ChartJS.register(ArcElement, Tooltip, Legend);

function Dashboard() {

  const { auth } = useAuth();

  const [users, setUsers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [pending, setPending] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [properties, setProperties] = useState([]);
  const [propertiesA, setPropertiesA] = useState([]);
  const [propertiesI, setPropertiesI] = useState([]);
  const [propertiesP, setPropertiesP] = useState([]);
  const [offline, setOffline] = useState([]);
  const [online, setOnline] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [date,setDate] = useState(new Date());

  const [donChart, setDonChart] = useState([]);

  const [donChartx, setDonChartx] = useState([]);


  

  async function getUsers(){
    const response = await axiosPrivate.get('/users');
    setUsers(response.data);
  }
  
  async function getBookings(){
    const response = await axiosPrivate.get('/bookings');
    setBookings(response.data);

    const pendingBookings = response.data.filter(item => {
      if(item.status === "pending") return item;
    });
    setPending(pendingBookings);

    const completedBookings = response.data.filter(item => {
      if(item.status === "completed") return item;
    });
    setCompleted(completedBookings); 

    const canceledBookings = response.data.filter(item => {
      if(item.status == "cancelled") return item;
    });
    setCanceled(canceledBookings)
    
  }
  
  async function getProperties(){
    const response = await axiosPrivate.get('/properties');
    if(auth?.roleId == 1){
      setProperties(response.data);
    }else{
      const propertiesHM = response.data.filter(item => {
        if(item.userId === auth.userId){
          return item;
        }
      }); 
      const propertiesHMActive = response.data.filter(item => {
        if(item.userId === auth.userId && item.status === 'active'){
          return item;
        }
      }); 
      const propertiesHMInactice = response.data.filter(item => {
        if(item.userId === auth.userId && item.status === 'inactive'){
          return item;
        }
      }); 
      const propertiesHMPending = response.data.filter(item => {
        if(item.userId === auth.userId && item.status === 'pending'){
          return item;
        }
      }); 
      setProperties(propertiesHM);
      setPropertiesA(propertiesHMActive);
      setPropertiesI(propertiesHMInactice);
      setPropertiesP(propertiesHMPending);
    }
  }
  
  async function getOrganisations(){
    const response = await axiosPrivate.get('/organisations');
    setOrganisations(response.data);
  }

  async function getTransactions(){
    const response = await axiosPrivate.get('/transactions');
    console.log(response.data);
    setTransactions(response.data);

    const offlineTrans = response.data.filter(item => {
      if(item.Payment.type === "offline") return item;
    });
    setOffline(offlineTrans);
    
    const onlineTrans = response.data.filter(item => {
      if(item.Payment.type === "online") return item;
    });
    setOnline(onlineTrans);
  }

  useEffect(() => {

    if (completed.length !== 0){
      const data = {
        labels: ["Pending", "Completed", "Canceled"],
        datasets: [
          {
            label: "Bookings",
            data: [pending.length, completed.length, canceled.length],
            backgroundColor: ["#007bff", "#4B49AC", "#FFC100"],
            borderColor: ["#007bff", "#7978E9", "#FFC100"],
            borderWidth: 1,
          },
        ],
      };
      setDonChart(<Doughnut data={data} style={{ maxWidth: '100%', maxHeight: '70%'}} />);
    }
    
    if (transactions.length !== 0){
      const datax = {
        labels: ["Offline", "Online"],
        datasets: [
          {
            label: "Transactions",
            data: [offline.length, online.length],
            backgroundColor: ["#007bff", "#4B49AC"],
            borderColor: ["#007bff", "#7978E9"],
            borderWidth: 1,
          },
        ],
      };
      setDonChartx(<Doughnut data={datax} style={{ maxWidth: '100%', maxHeight: '70%'}} />);
    }

    setInterval(()=>setDate(new Date()), 1000 );

    getUsers();
    getBookings();
    getProperties();
    getTransactions();
    getOrganisations();
  }, [pending, completed, canceled]);

    return (
      <>
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="row">
              <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                <h3 className="font-weight-bold">Welcome</h3>
                <h6 className="font-weight-normal mb-0">All systems are running smoothly! 
                {/* You have <span className="text-primary">3 unread alerts!</span> */}
                </h6>
              </div>
              <div className="col-12 col-xl-4">
              
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div class="card tale-bg">
              <div class="card-people pt-0">
                <img src="images/dashboard/people.jpg" alt="people"/>
                <div class="weather-info">
                  <div class="d-flex">
                    <div>
                      <h1 class="mb-0 font-weight-normal text-white"><i class="icon-clock mr-2"></i></h1>
                    </div>
                    <div class="pt-4">
                      <h4 class="location font-weight-normal text-white">{date.toLocaleTimeString()}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            (auth?.roleId == 1)?
            (
              <>
                 <div className="col-md-6 grid-margin transparent">
                  <div className="row">
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <div className="card card-tale">
                        <div className="card-body">
                          <p className="mb-4"><b>BOOKINGS</b></p>
                          <p className="fs-30 mb-2">{bookings.length}</p>
                          <p>Total number of bookings.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <div className="card card-dark-blue">
                        <div className="card-body">
                          <p className="mb-4"><b>USERS</b></p>
                          <p className="fs-30 mb-2">{users.length}</p>
                          <p>Total number of users.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                      <div className="card card-light-blue">
                        <div className="card-body">
                          <p className="mb-4"><b>PROPERTIES</b></p>
                          <p className="fs-30 mb-2">{properties.length}</p>
                          <p>Total mumber of properties.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 stretch-card transparent">
                      <div className="card card-light-danger">
                        <div className="card-body">
                          <p className="mb-4"><b>ORGANISATIONS</b></p>
                          <p className="fs-30 mb-2">{organisations.length}</p>
                          <p>Total number of Organisations</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
            :(
              <>
                 <div className="col-md-6 grid-margin transparent">
                  <div className="row">
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <div className="card card-tale">
                        <div className="card-body">
                          <p className="mb-4"><b>ALL PROPERTIES</b></p>
                          <p className="fs-30 mb-2">{properties.length}</p>
                          <p>Total number of properties.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <div className="card card-dark-blue">
                        <div className="card-body">
                          <p className="mb-4"><b>ACTIVE PROPERTIES</b></p>
                          <p className="fs-30 mb-2">{propertiesA.length}</p>
                          <p>Total number of active properties.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                      <div className="card card-light-blue">
                        <div className="card-body">
                          <p className="mb-4"><b>INACTIVE PROPERTIES</b></p>
                          <p className="fs-30 mb-2">{propertiesI.length}</p>
                          <p>Total mumber of inactive properties.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 stretch-card transparent">
                      <div className="card card-light-danger">
                        <div className="card-body">
                          <p className="mb-4"><b>PENDING PROPERTIES</b></p>
                          <p className="fs-30 mb-2">{propertiesP.length}</p>
                          <p>Total number of pending properties</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
          

         
        </div>

        {
          (auth?.roleId == 1)?
          (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                      <p className="card-title text-danger">Booking Reports</p>
                      {/* <p className="font-weight-500">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p> */}
                      <div className="d-flex flex-wrap mb-1">
                        <div className="mr-5 ">
                          <p className="text-dark">Pending</p>
                          <h3 className="text-primary fs-20 font-weight-medium">{pending.length}</h3>
                        </div>
                        <div className="mr-5 ">
                          <p className="text-dark">Completed</p>
                          <h3 className="text-primary fs-20 font-weight-medium">{completed.length}</h3>
                        </div>
                        <div className="">
                          <p className="text-dark">Canceled</p>
                          <h3 className="text-primary fs-20 font-weight-medium">{canceled.length}</h3>
                        </div> 
                      </div>
                      {donChart}
                    </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                      <p className="card-title text-danger">Transaction Reports</p>
                      {/* <p className="font-weight-500">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p> */}
                      <div className="d-flex flex-wrap mb-1">
                        <div className="mr-5 ">
                          <p className="text-dark">Online</p>
                          <h3 className="text-primary fs-20 font-weight-medium">{online.length}</h3>
                        </div>
                        <div className="mr-5 ">
                          <p className="text-dark">Offline</p>
                          <h3 className="text-primary fs-20 font-weight-medium">{offline.length}</h3>
                        </div>
                      </div>
                      {donChartx}
                      {/* <Doughnut data={data} style={{ maxWidth: '100%', maxHeight: '70%'}} /> */}
                      {/* <canvas id="order-chart"></canvas> */}
                    </div>
                </div>
              </div>
            </div>
          </>
          )
          :(<></>)
        }
        
    </>
    );
  }
  
  export default Dashboard;
  