import React, { useEffect, useState } from 'react'
import { axiosPrivate } from '../../../Apis/axios';

function Update(props) {

    const url = "/roles";
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Update");

    const [options, setOptions] = useState();
    const optionsValue = [];
    

    useEffect(()=>{
        let opt = [];

        if(props.hasOwnProperty("opt")) opt = props.opt; 

        if(opt){
            const optionsArray =  opt.map((item)=> {
                optionsValue.push(item.id)
                return (
                    <option key={item.id} value={item.id}>
                        {item.title}
                    </option>
                )
            });
        
             setOptions(optionsArray)
        }
        
    }, [props])
    


    async function handleUpdate(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)

        const inputValues = {
            id: event.target.elements.id.value,
            name: event.target.elements.title.value,
            slug: event.target.elements.slug.value,
            description: event.target.elements.description.value
        }
        // console.log(props.opt)
        const permissionsInput = event.target.elements.permissions.selectedOptions;

        try{
            // Axios API.
            const response = await axiosPrivate.put(url+"/"+inputValues.id, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            if((response.status == 200) && (permissionsInput.length > 0)){
                // remove permissions from role
                const removePermissionsArray = props.opt.map((item) => item.id );
                const removedPermissions = await axiosPrivate.post("/roles/permissions/remove",
                JSON.stringify({
                    id: inputValues.id,
                    permissions: removePermissionsArray
                }));
                // add permissions to role
                if(removedPermissions.status == 200)
                {
                    const permissionsArray = Array.from(permissionsInput).map((item) => item.value);
                    await axiosPrivate.post("/roles/permissions",
                    JSON.stringify({
                        id: inputValues.id,
                        permissions: permissionsArray
                    }));
                }
               
            }

            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");

            // Print Out Responses.
            //console.log(response?.data);
            setSuccess("Role updated successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }

    return (
        <div className="modal fade" id="roleUpdateModal" tabIndex="-1" role="dialog" aria-labelledby="roleUpdateModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <form onSubmit={handleUpdate}>
    
                        <div className="modal-header py-2 text-white bg-dark">
                            <h5 className="modal-title" id="exampleModalLongTitle">Update Role</h5>
                        </div>
        
                        <div className="modal-body py-3">
                            <span className="text-danger small"><b>{error}</b></span>
                            <span className="text-success small"><b>{success}</b></span>
                        
                            <input type="hidden" name='id' defaultValue={props.roleId} />
                            <input type="hidden" name='permissionIds' defaultValue={props.roleId} />
                            <div className="row pb-2">
                                <div className="col-md-6">
                                    <label htmlFor="titleInput" className='font-weight-medium'>Title: <span className='text-danger'>*</span></label>
                                    <input type="text" id='titleInput' defaultValue={props.title} name='title' className='form-control form-control-sm'  placeholder='Role title' required />
                                </div>
                                <div className="col-md-6"></div>
                            </div>
    
                            <div className="row py-2">
                                <div className="col-md-6">
                                    <label htmlFor="slugInput" className='font-weight-medium'>Slug: <span className='text-danger'>*</span></label>
                                    <input type="text" id='slugInput' defaultValue={props.slug} name='slug' className='form-control form-control-sm'  placeholder='Role slug' required />
                                </div>
                                <div className="col-md-6"></div>
                            </div>

                            <div className="row py-2">
                                <div className="col-md-6">
                                    <label htmlFor="parentInput" className='font-weight-medium'>Permissions: <span className='text-danger'></span></label>
                                    <select name="permissions" className='form-control form-control-sm' multiple>
                                        {/* <option value={0}> None </option> */}
                                        {options}
                                    </select>
                                </div>
                                <div className="col-md-6"></div>
                            </div>
    
                            <div className="row py-2">
                                <div className="col-md-10">
                                    <label htmlFor="descInput" className='font-weight-medium'>Description: </label>
                                    <textarea defaultValue={props.description} className='form-control form-control-sm' name="description" id="descInput" cols="30" rows="3"></textarea>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                        </div>

                        <div className="modal-footer py-1">
                            <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                            <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                                {submit}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      );
    }

export default Update;