import { useEffect, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";

function Stats() {

    const [total, setTotal] = useState(0);
    const url = "/states";

    async function getAll(){

        try{
            const response  = await axiosPrivate.get(url);
            setTotal(response.data.length);
        } catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getAll();
    }, []);

    return (
      <>
          <div className="row">
              <div className="col-md-4 mb-4 stretch-card transparent">
                  <div className="card card-tale">
                  <div className="card-body">
                      <p className="mb-4">Total</p>
                      <p className="fs-30 mb-2">{total}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div>
              {/* <div className="col-md-4 mb-4 stretch-card transparent">
                  <div className="card card-dark-blue">
                  <div className="card-body">
                      <p className="mb-4">Top</p>
                      <p className="fs-30 mb-2">{0}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div> */}
          </div>
      </>
    )
  }
  
  export default Stats