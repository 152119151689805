import { useEffect, useRef, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";
import $ from 'jquery';
import Delete from "./Delete";
$.DataTable = require('datatables.net-bs5');


function Table() {

  const tableRef = useRef()
  const tableName = "table-uploads"
  const url = "/uploads";

  const [data, setData] = useState();

  const [updateId, setUpdateId] = useState("");

  const actions = `<button class="btn delete-btn" data-toggle="modal" data-target="#uploadDeleteModal"><i class="ti-trash text-danger"></i> </button>`;

  const columns =  [
      { title: "ID"},
      { title: "User"},
      { title: "Type"},
      { title: "Url"},
      { title: "Format"},
      { title: "Actions"},
  ];

  async function getAll(){
    try{
      // Axios API.
      const response = await axiosPrivate.get(url);

      // Print Out Responses.
      // console.log(response);

      // Set Data.
      const formatedData = await response?.data?.map((upload)=>{
        return [
          upload.id,
          upload.User.firstName+" "+upload.User.lastName,
          upload.type,
          `<a href="${upload.url}">${upload.url}</a>`,
          upload.format,
        ]
      })

      setData(formatedData);

    }catch(e){
      console.error(e);
    }
  }


  function setDeleteInformation(data){
    setUpdateId(data[0]);
  }

  useEffect(() => { getAll() }, []);
  
  const table = $(tableRef.current).DataTable({
    data: data,
    columns: columns,
    dom: "<'d-flex justify-content-between'<B><f>>rt<'d-flex justify-content-between'<l><p>>i<'clear'>",
    pageLength: 5,
    lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
    buttons: [
      // { extend: 'pdf', className: 'btn btn-sm btn-dark' },
      { extend: 'csv', className: 'btn btn-sm btn-dark' },
    ],
    columnDefs: [
      {
          targets: -1,
          data: null,
          render: function () {
            return actions;
        }
      },
    ],
    drawCallback: () => {
      $('.form-control').addClass('form-control-sm');
      $('.active > .page-link').addClass('bg-dark');
    },
    destroy: true,     
  });
  $('#table-uploads tbody').on('click', 'tr', function () {
    if ($(this).parent("tr").hasClass('selected')) {
        $(this).removeClass('selected');
    } else {
        table.$("tr.selected").removeClass('selected');
        $(this).addClass('selected');
    }
  });
  $("#table-uploads tbody").on("click", "button", function () {
    let data = table.row($(this).parents("tr")).data();
    if(data) setDeleteInformation(data)
  });
  


  return (
    <>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="card-title text-danger">All Uploads</h4>
                {/* <button type="button" className="btn btn-danger btn-sm btn-icon-text" data-toggle="modal" data-target="#transactionModal">
                  <i className="ti-file btn-icon-prepend"></i> Add Transaction
                </button> */}
              </div>
              <div className="table-responsive pt-3">
                <table className="display table table-striped" width="100%" id={tableName} ref={ tableRef }></table>
              </div>
            </div>
          </div>
        </div>
        
        <Delete uploadId={updateId} />
    </>
  );

}
export default Table;