import React, { useState } from 'react'
import { axiosPrivate } from '../../../Apis/axios';

function Delete(props) {
    const url = "/uploads";
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Delete");


    async function handleDelete(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)

        const inputValues = {
            id: event.target.elements.id.value,
        }

        try{
            // Axios API.
            const response = await axiosPrivate.delete(url+"/"+inputValues.id, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            event.target.elements.submitBtn.disabled = false;
            setSubmit("Delete");

            // Print Out Responses.
            console.log(response?.data);
            setSuccess("Upload deleted successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Delete");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }
    return (
        <div className="modal fade" id="uploadDeleteModal" tabIndex="-1" role="dialog" aria-labelledby="uploadDeleteModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <form onSubmit={handleDelete}>
                        <div className="modal-header py-2 text-white bg-dark">
                            <h5 className="modal-title" id="exampleModalLongTitle">Delete Upload</h5>
                        </div>
        
                        <div className="modal-body">
                            <input type="hidden" name='id' defaultValue={props.uploadId} />
                            <div className="pb-2">
                                Are you sure you would like to <b className='text-danger'>DELETE</b> this item?
                            </div>
                        </div>

                        <div className="modal-footer py-1">
                            <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                            <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                                {submit}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      );
    }

export default Delete;
