/* eslint-disable eqeqeq */
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";

function Sidebar() {

    const { auth } = useAuth();
    const location = useLocation();
    const path = location.pathname;
    console.log(auth);
    // console.log("route", location.pathname );

    return (
        (auth?.roleId == 1)?

        (<nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                <li className={path === "/dashboard" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link " to="/dashboard">
                        <i className="icon-grid menu-icon"></i>
                        <span className="menu-title">Dashboard</span>
                    </Link>
                </li>
                <li className={path === "/amenities" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/amenities">
                        <i className="icon-tag menu-icon"></i>
                        <span className="menu-title">Amenities</span>
                    </Link>
                </li>
                <li className={path === "/clients" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/clients">
                        <i className="icon-contract menu-icon"></i>
                        <span className="menu-title">Corporate Clients</span>
                    </Link>
                </li>
                <li className={path === "/bookings" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/bookings">
                        <i className="icon-paper menu-icon"></i>
                        <span className="menu-title">Bookings</span>
                    </Link>
                </li>
                <li className={path === "/categories" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/categories">
                        <i className="icon-layout menu-icon"></i>
                        <span className="menu-title">Categories</span>
                    </Link>
                </li>
                <li className={path === "/locations" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/locations">
                        <i className="icon-globe menu-icon"></i>
                        <span className="menu-title">Locations</span>
                    </Link>
                </li>
               
                <li className={path === "/payments" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/payments">
                        <i className="icon-box menu-icon"></i>
                        <span className="menu-title">Payments</span>
                    </Link>
                </li>
                <li className={path === "/properties" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/properties">
                        <i className="icon-map menu-icon"></i>
                        <span className="menu-title">Properties</span>
                    </Link>
                </li>
                <li className={path === "/roles" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/roles">
                        <i className="icon-menu menu-icon"></i>
                        <span className="menu-title">Roles</span>
                    </Link>
                </li>
                <li className={path === "/rooms" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/rooms">
                        <i className="icon-grid-2 menu-icon"></i>
                        <span className="menu-title">Rooms</span>
                    </Link>
                </li>
                <li className={path === "/transactions" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/transactions">
                        <i className="icon-paper-stack menu-icon"></i>
                        <span className="menu-title">Transactions</span>
                    </Link>
                </li>
                <li className={path === "/uploads" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/uploads">
                        <i className="icon-cloud-upload menu-icon"></i>
                        <span className="menu-title">Uploads</span>
                    </Link>
                </li>
                <li className={path === "/users" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/users">
                        <i className="icon-head menu-icon"></i>
                        <span className="menu-title">Users</span>
                    </Link>
                </li>
                {/* <li className={path === "/dashboard" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/notifications">
                        <i className="icon-bell menu-icon"></i>
                        <span className="menu-title">Notifications</span>
                    </Link>
                </li> */}

            </ul>
        </nav>)
        : (<nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
            <li className={path === "/dashboard" ? "nav-item active" : "nav-item"} >
                <Link className="nav-link " to="/dashboard">
                    <i className="icon-grid menu-icon"></i>
                    <span className="menu-title">Dashboard</span>
                </Link>
            </li>
            <li className={path === "/bookings" ? "nav-item active" : "nav-item"} >
                <Link className="nav-link" to="/bookings">
                    <i className="icon-paper menu-icon"></i>
                    <span className="menu-title">Bookings</span>
                </Link>
            </li>
            <li className={path === "/properties" ? "nav-item active" : "nav-item"} >
                <Link className="nav-link" to="/properties">
                    <i className="icon-map menu-icon"></i>
                    <span className="menu-title">Properties</span>
                </Link>
            </li>
            <li className={path === "/rooms" ? "nav-item active" : "nav-item"} >
                <Link className="nav-link" to="/rooms">
                    <i className="icon-grid-2 menu-icon"></i>
                    <span className="menu-title">Rooms</span>
                </Link>
            </li>
            {/* <li className={path === "/transactions" ? "nav-item active" : "nav-item"} >
                <Link className="nav-link" to="/transactions">
                    <i className="icon-paper-stack menu-icon"></i>
                    <span className="menu-title">Transactions</span>
                </Link>
            </li> */}
        
            
            {/* <li className={path === "/dashboard" ? "nav-item active" : "nav-item"} >
                <Link className="nav-link" to="/notifications">
                    <i className="icon-bell menu-icon"></i>
                    <span className="menu-title">Notifications</span>
                </Link>
            </li> */}

        </ul>
    </nav>)
    );
  }
  
  export default Sidebar;
  