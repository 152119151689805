import React, { useState } from 'react'
import { axiosPrivate } from '../../../Apis/axios';

function Update(props) {

    const url = "/transactions";
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Update");
    const opts = ['pending', 'completed', 'cancelled']
    //const [options, setOptions] = useState();
    const options = [];

    let optionValues  = '';
    if (props.status){
        optionValues = opts.map((item) => {
            let selected = false;
            if(props.status == item) { selected = "selected"; }
            return ( <option key={item} value={item} selected={selected}>{item}</option>)
        });
        options.push(optionValues)
    }


    async function handleUpdate(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)

        const inputValues = {
            id: event.target.elements.id.value,
            status: event.target.elements.status.value,
            reference: event.target.elements.reference.value,
        }

        try{
            // Axios API.
            const response = await axiosPrivate.put(url+"/"+inputValues.id, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");

            // Print Out Responses.
            console.log(response?.data);
            setSuccess("Transaction Updated successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }

    return (
        <div className="modal fade" id="transactionUpdateModal" tabIndex="-1" role="dialog" aria-labelledby="transactionUpdateModal" aria-hidden="true">
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content border-0">
                    <form onSubmit={handleUpdate}>
    
                        <div className="modal-header py-2 text-white bg-dark">
                            <h5 className="modal-title" id="exampleModalLongTitle">Update Transaction</h5>
                        </div>
        
                        <div className="modal-body py-3">
                            <span className="text-danger small"><b>{error}</b></span>
                            <span className="text-success small"><b>{success}</b></span>
                        
                            <input type="hidden" name='id' defaultValue={props.transactionId} />
                            <div className="row py-2">
                                <div className="col-md-8">
                                    <label htmlFor="parentInput" className='font-weight-medium'>Status: <span className='text-danger'></span></label>
                                    <select name="status" className='form-control form-control-sm'>
                                        {options}
                                    </select>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <div className="row py-2">
                                <div className="col-md-12">
                                    <label htmlFor="parentInput" className='font-weight-medium'>Reference: <span className='text-danger'></span></label>
                                    <input type="text" name="reference" className='form-control' id="" defaultValue={props.reference} />
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        </div>

                        <div className="modal-footer py-1">
                            <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                            <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                                {submit}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      );
    }

export default Update;
