import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../../Apis/axios';
import useAuth from '../../../Hooks/useAuth';

function Update(props) {
    const url = "/organisations";
    const { auth } = useAuth();
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Update");
    
    async function handleUpdate(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)
        
        //console.log(auth.userId);

        const inputValues = {
            id: event.target.elements.id.value,
            name: event.target.elements.name.value,
            email: event.target.elements.email.value,
            telephone: event.target.elements.telephone.value,
            userId: auth.userId,
            address: event.target.elements.address.value,
            description: event.target.elements.description.value
        }    

        console.log(inputValues);
        
        try{
            // Axios API.
            const response = await axiosPrivate.put(url+"/"+inputValues.id, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
                event.target.elements.submitBtn.disabled = false;
                setSubmit("Update");
                
            console.log(response?.data);
            // Print Out Responses.

            setSuccess("Corporate client Updated successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }

  return (
    <div className="modal fade" id="organisationUpdateModal" tabIndex="-1" role="dialog" aria-labelledby="#organisationUpdateModal" aria-hidden="true">
        <div className="modal-dialog modal-md" role="document">
            <div className="modal-content border-0">
                <form onSubmit={handleUpdate}>
                    <div className="modal-header py-2 text-white bg-dark">
                        <h5 className="modal-title" id="exampleModalLongTitle">Update Corporate Client</h5>
                    </div>
    
                    <div className="modal-body py-3">
                    <span className="text-danger small"><b>{error}</b></span>
                    <span className="text-success small"><b>{success}</b></span>
                    <input type="hidden" name='id' defaultValue={props.organisationData[0]} />

                        <div className="row py-3">
                            <div className="col-md-5 px-2">
                                <label htmlFor="clientInput" className='font-weight-medium'>Corporate Client: <span className='text-danger'>*</span></label>
                                <input type="text" id='clientInput' defaultValue={props.organisationData[1]} name='name' className='form-control form-control-sm'  placeholder='Corporate Client' required />
                            </div>
                            <div className="col-md-7 px-2">
                                <label htmlFor="emailInput" className='font-weight-medium'>Email: <span className='text-danger'>*</span></label>
                                <input type="email" id='emailInput' defaultValue={props.organisationData[2]} name='email' className='form-control form-control-sm'  placeholder='Email' required />
                            </div>
                        </div>

                        <div className="row py-3">
                            
                            <div className="col-md-5 px-2">
                                <label htmlFor="telInput" className='font-weight-medium'>Phone NO.: <span className='text-danger'>*</span></label>
                                <input type="tel" id='telInput' defaultValue={props.organisationData[3]} name='telephone' className='form-control form-control-sm'  placeholder='Phone NO.'  />
                            </div>
                            
                            <div className="col-md-7 px-2">
                                <label htmlFor="address" className='font-weight-medium'>Address: <span className='text-danger'></span></label>
                                <input type="text" id='address' defaultValue={props.organisationData[4]} name='address' className='form-control form-control-sm'  placeholder='Address' />
                            </div>
                        </div>
                       
                        <div className="row py-3">

                            <div className="col px-2">
                                <label htmlFor="descInput" className='font-weight-medium'>Description: <span className='text-danger'></span></label>
                                <textarea name="description" defaultValue={props.organisationData[5]} className="form-control form-control-sm" id="descInput" cols="30" rows="3"></textarea>
                            </div>

                        </div>
                        
                    </div>

                    <div className="modal-footer py-1">
                        <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                        <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                            {submit}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
}

export default Update;