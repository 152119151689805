import axios from "../Apis/axios";
import useAuth from "./useAuth";

function useRefreshToken() {
    const { setAuth } = useAuth();

    async function refresh() {
        const response = await axios.get('/auth/token', {
            withCredentials: true
        });
        setAuth(prev => {
            // console.log(JSON.stringify(prev));
             console.log(response);
            return { ...prev, accessToken: response.data.accessToken, userId: response.data.userId, roleId: response.data.roleId }
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken