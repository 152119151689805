import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../../Apis/axios';
import { Widget } from '@uploadcare/react-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';

const UPLOAD_PUBLIC_KEY = "d56c8b8e1e84d37ee09a";

function Create() {

    const url = "/payments";
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Create");
    const [imageValue, setImageValue] = useState("");
    const [formStatus, setFormStatus] = useState(true);
    

    async function handleCreate(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)

        let inputValues = {};
        console.log(event.target.elements.type.value)
        if(event.target.elements.type.value == "online"){
            inputValues = {
                title: event.target.elements.title.value,
                type: event.target.elements.type.value,
                status: event.target.elements.status.value,
                image: imageValue,
                url: event.target.elements.url.value,
                secretKey: event.target.elements.secret.value,
                publicKey: event.target.elements.public.value,
                bankName: "",
                accountName: "",
                accountNumber: "",
                swift: "",
                sort: "",
                iban: ""
            };
        }else{
            inputValues = {
                title: event.target.elements.title.value,
                type: event.target.elements.type.value,
                status: event.target.elements.status.value,
                image: imageValue,
                url: "",
                secretKey: "",
                publicKey: "",
                bankName: event.target.elements.bank.value,
                accountName: event.target.elements.accountName.value,
                accountNumber: event.target.elements.accountNO.value,
                swift: event.target.elements.swift.value,
                sort: event.target.elements.sort.value,
                iban: event.target.elements.iban.value
            };
            
        }

        try{
            // Axios API.
            const response = await axiosPrivate.post(url, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            event.target.elements.submitBtn.disabled = false;
            setSubmit("Create");

            // Print Out Responses.
            console.log(response?.data);
            setSuccess("Payment created successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Create");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }
    
  return (
    <div className="modal fade" id="paymentModal" tabIndex="-1" role="dialog" aria-labelledby="#paymentModal" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content border-0">
                    <form onSubmit={handleCreate}>
                        <div className="modal-header py-2 text-white bg-dark">
                            <h5 className="modal-title" id="exampleModalLongTitle">Create Payment</h5>
                        </div>
        
                        <div className="modal-body py-3">
                        <span className="text-danger small"><b>{error}</b></span>
                        <span className="text-success small"><b>{success}</b></span>

                            <div className="row py-2">
                                <div className="col-md-4 px-2">
                                    <label htmlFor="titleInput" className='font-weight-medium'>Title: <span className='text-danger'>*</span></label>
                                    <input type="text" id='titleInput' name='title' className='form-control form-control-sm'  placeholder='Title' required />
                                </div>

                                <div className="col px-2">
                                    <label htmlFor="status" className='font-weight-medium'>Status: <span className='text-danger'>*</span></label>
                                    <select name="status" id="status" className='form-control form-control-sm'>
                                        <option value="deactivated">Inactive</option>
                                        <option value="active">Active</option>
                                    </select>
                                </div>
                                <div className="col px-2">
                                    <label htmlFor="type" className='font-weight-medium'>Type: <span className='text-danger'>*</span></label>
                                    <select name="type" id="type" onChange={(e) => { (e.target.value == "online")? setFormStatus(true) :setFormStatus(false) }} className='form-control form-control-sm'>
                                        <option value="online">Online</option>
                                        <option value="offline">Offline</option>
                                    </select>
                                </div>
                                <div className="col-md-3 px-2">
                                    <label className='font-weight-medium'>Logo: <span className='text-danger'></span></label>
                                    <br />
                                    <div className="">
                                        <Widget 
                                        publicKey={UPLOAD_PUBLIC_KEY}
                                        tabs='file'
                                        customTabs={{ preview: uploadcareTabEffects}}
                                        previewStep='true'
                                        imagesOnly='true'
                                        effects="crop"
                                        multipartPartSize="10480"
                                        onChange={(file) => setImageValue(file.cdnUrl) }
                                        crop='free, 16:9, 4:3, 5:4, 1:1'
                                        />
                                    </div>
                                </div>
                            </div>

                            <br />

                            { formStatus?

                                (<div>
                                    <h4 className='text-danger'>Online</h4>
                                    <hr />

                                    <div className="row py-2">
                                        <div className="col-md-5 px-2">
                                            <label htmlFor="url" className='font-weight-medium'>URL: <span className='text-danger'>*</span></label>
                                            <input type="url" id='url' name='url' className='form-control form-control-sm'  placeholder='Url' required />
                                        </div>
                                        <div className="col px-2">
                                            <label htmlFor="public" className='font-weight-medium'>Public Key: <span className='text-danger'>*</span></label>
                                            <input type="text" id='public' name='public' className='form-control form-control-sm'  placeholder='Public Key' required />
                                        </div>
                                        <div className="col px-2">
                                            <label htmlFor="secret" className='font-weight-medium'>Secret Key: <span className='text-danger'>*</span></label>
                                            <input type="text" id='secret' name='secret' className='form-control form-control-sm'  placeholder='Secret Key' required />
                                        </div>
                                    </div>
                                </div>)
                                :
                                (<div>
                                    <h4 className='text-danger'>Offline</h4>
                                    <hr />

                                    <div className="row py-2">
                                        <div className="col-md-4 px-2">
                                            <label htmlFor="bank" className='font-weight-medium'>Bank Name: <span className='text-danger'>*</span></label>
                                            <input type="text" id='bank' name='bank' className='form-control form-control-sm'  placeholder='Bank Name' required />
                                        </div>
                                        <div className="col-md-4 px-2">
                                            <label htmlFor="accountName" className='font-weight-medium'>Account Name: <span className='text-danger'>*</span></label>
                                            <input type="text" id='accountName' name='accountName' className='form-control form-control-sm'  placeholder='Account Name' required />
                                        </div>
                                    
                                        <div className="col-md-4 px-2">
                                            <label htmlFor="accountNO" className='font-weight-medium'>Account Number: <span className='text-danger'>*</span></label>
                                            <input type="text" id='accountNO' name='accountNO' className='form-control form-control-sm'  placeholder='Account NO' required />
                                        </div>
                                        
                                    </div>
                            
                                    <div className="row py-2">
                                        <div className="col-md-4 px-2">
                                            <label htmlFor="iban" className='font-weight-medium'>Iban: <span className='text-danger'></span></label>
                                            <input type="text" id='iban' name='iban' className='form-control form-control-sm'  placeholder='Iban NO'  />
                                        </div>
                                        <div className="col-md-4 px-2">
                                            <label htmlFor="sort" className='font-weight-medium'>Sort Code: <span className='text-danger'></span></label>
                                            <input type="text" id='sort' name='sort' className='form-control form-control-sm'  placeholder='Sort Code' />
                                        </div>
                                        <div className="col-md-4 px-2">
                                            <label htmlFor="swift" className='font-weight-medium'>Swift Code: <span className='text-danger'></span></label>
                                            <input type="text" id='swift' name='swift' className='form-control form-control-sm'  placeholder='Swift Code'  />
                                        </div>
                                    </div>
                                </div>)
                            }

                        </div>

                        <div className="modal-footer py-1">
                            <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                            <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                                {submit}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
  );
}

export default Create;
