import Create from "./Create";
import Stats from "./Stats";
import Table from "./Table";

function Uploads() {
  return (
    <div>
        {/* <Stats /> */}
        <Table />
        <Create />
    </div>
  );
}

export default Uploads;
