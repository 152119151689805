import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios, { axiosPrivate } from "../../Apis/axios";
import AuthContext from "../../Context/AuthProvider";

const url = '/auth/sign-up';

function Register(){

  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [error, setError] = useState();

  const [showPass, setShowPass] = useState(false);
    const [passIcon, setPassIcon] = useState("icon-lock");
    const [passType, setPassType] = useState("password");

    function handlePassView(){
        if (showPass){
            setShowPass(false);
            setPassIcon("icon-lock");
            setPassType("password");
        }else{
            setShowPass(true);
            setPassIcon("icon-eye");
            setPassType("text")
        }
    }
  
  
  const navigate = useNavigate();
  const [countries, setCountries] = useState();
  let [states, setStates] = useState();
  const { setAuth } = useContext(AuthContext);

  const [submit, setSubmit] = useState("Sign Up");

  async function handleRegistration(event){
    event.preventDefault();
    event.target.elements.submitBtn.disabled = true;
    setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)
    
    const inputValues = {
        firstName: event.target.elements.firstNameInput.value,
        lastName: event.target.elements.lastNameInput.value,
        email: event.target.elements.emailInput.value,
        telephone: event.target.elements.telephoneInput.value,
        state: event.target.elements.stateInput.value,
        password: event.target.elements.passwordInput.value,
        confirmPassword: event.target.elements.confirmPasswordInput.value
    }

    //console.log(inputValues);
    // Input Validation 
    if(inputValues.password !== inputValues.confirmPassword) {
      //console.log("hello");
      event.target.elements.confirmPasswordInput.setCustomValidity("Passwords don't match");
    } else {
      event.target.elements.confirmPasswordInput.setCustomValidity('');
    }

    try{
        // Axios API.
        const response = await axiosPrivate.post(url, 
          JSON.stringify({
            firstName: inputValues.firstName,
            lastName: inputValues.lastName,
            email: inputValues.email,
            password: inputValues.password,
            stateId: inputValues.state,
            status: "active",
            roleId: 2
          })
          , {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });

        event.target.elements.submitBtn.disabled = false;
        setSubmit("Sign Up");

        // Print out responses
        setAuth(response?.data);
        navigate("/dashboard");
        // console.log(JSON.stringify(response))
        
    }catch(e){
      event.target.elements.submitBtn.disabled = false;
      setSubmit("Sign Up");
      if (e.response.data.error.hasOwnProperty("firstName")){
          setFirstNameError(e.response.data.error.firstName);
      }else if (e.response.data.error.hasOwnProperty("lastName")){
          setLastNameError(e.response.data.error.lastName);
      }else if (e.response.data.error.hasOwnProperty("email")){
          setEmailError(e.response.data.error.email);
      }else if (e.response.data.error.hasOwnProperty("password")){
          setPasswordError(e.response.data.error.password);
      }else {
          console.error(e);
          setError(e.response.data.error);
      } 
    }
  }

  async function getCountries(){
    try{
      const countriesResponse =  await axios.get("/countries");
      // Print out responses
      const countryList = countriesResponse?.data.map((item) => {
          return(
            <option key={item.id} value={item.id}>
            {item.name}
          </option>
        )
      });
    setCountries(countryList);
    //console.log(countriesResponse?.data);
    }catch(e){
      console.error(e);
    }
  }

  async function getStates(event){
    const countryId = event.target.value
    console.log(typeof countryId);
    if (countryId > 0) {
      try{
        const statesResponse = await axios.get("/states/country/"+countryId);
        //console.log(statesResponse?.data);
        const stateList = statesResponse?.data.map((item) => {
          return(
            <option key={item.id} value={item.id}>
            {item.name}
          </option>
        )
      });
      setStates(stateList);

      }catch(e){
        console.error(e);
      }
    }else{
      setStates("");
    }
  }

  useEffect(()=>{
    getCountries();
  }, []);

    return(
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src="../../images/hb-name-logo.png" alt="logo"/>
              </div>
              <h4>New here?</h4>
              <h6 className="font-weight-light">Signing up is easy. It only takes a few steps</h6>
              <br />
              <span className="text-danger small"><b>{error}</b></span>
              
              <form className="pt-3" onSubmit={handleRegistration}> 

                <div className="row">
                    <div className="form-group col-md-6">
                        <input type="text" 
                        className="form-control form-control-sm" 
                        name="firstNameInput" 
                        pattern="[a-zA-Z0-9]{3,30}" 
                        placeholder="First Name"
                        title="min: 3 characters max: 30 characters." 
                        required/>
                        <span className="text-danger small"><b>{firstNameError}</b></span>
                    </div>
                    <div className="form-group col-md-6">
                        <input type="text"
                        className="form-control form-control-sm" 
                        name="lastNameInput" 
                        pattern="[a-zA-Z0-9]{3,30}" 
                        placeholder="Last Name" 
                        title="min: 3 characters max: 30 characters." 
                        required/>
                        <span className="text-danger small"><b>{lastNameError}</b></span>
                    </div>
                </div>

                <div className="form-group">
                  <input type="email" className="form-control form-control-sm" name="emailInput" placeholder="Email" required/>
                  <span className="text-danger small"><b>{emailError}</b></span>

                </div>

                <div className="row">
                    <div className="form-group col-md-6">
                        <select onChange={getStates} className="form-control form-control-sm" required>
                            <option value={0}>Country</option>
                            {countries}
                        </select>
                    </div>
                    <div className="form-group col-md-6">
                        <select className="form-control form-control-sm" name="stateInput" required>
                            <option value={0}>State</option>
                            {states}
                        </select>
                    </div>
                </div>
                
                <div className="form-group">
                  <input type="tel" 
                  className="form-control form-control-sm" 
                  name="telephoneInput" 
                  pattern="[0-9]{10,12}" 
                  placeholder="Telephone"
                  />
                  
                </div>


                    <div className="form-group">
                      <div class="input-group flex-nowrap">
                          <input type={passType}
                            className="form-control form-control-sm border-0 border-bottom" 
                            name="passwordInput" 
                            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}" 
                            placeholder="Password" 
                            title="min: 8 characters, max: 24 characters, special character, uppercase character and number." 
                            required/>
                          <span class="input-group-text bg-white border-top-1 border-end-0" onClick={()=>{ handlePassView() }} id="addon-wrapping"><i className={passIcon}></i></span>
                          <span className="text-danger small"><b>{passwordError}</b></span>
                      </div>
                      
                    </div>
                    <div className="form-group">
                        <input type={passType} className="form-control form-control-sm" name="confirmPasswordInput" placeholder="Confirm Password" required/>
                    </div>
                

                <div className="mb-4">
                  <div className="pl-4">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input" required/>
                        I agree to all Terms & Conditions
                    </label>
                  </div>
                </div>
                <div className="mt-3">
                  <button name="submitBtn" className="btn btn-block btn-danger btn-md font-weight-medium" type="submit">
                    {submit}
                  </button>
                </div>
                <div className="text-center mt-4 font-weight-light">
                  Already have an account? <Link to="/login" className="text-danger">Login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
    );
}

export default Register;