import Create from "./Create";
import Stats from "./Stats";
import Table from "./Table";

function Roles() {
  return (
    <div>
        <Stats />
        <Table />
        <Create />
    </div>
  );
}

export default Roles;
