import { useEffect, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";
import useAuth from "../../../Hooks/useAuth";

function Stats() {

    const url = "/properties";
    const { auth } = useAuth();
    const [total, setTotal] = useState(0);
    const [active, setActive] = useState(0);
    const [inactive, setInactive] = useState(0);

    async function getAll(){
        try {
            const response = await axiosPrivate.get(url);
            setTotal(response.data.length);
            setInactive(response.data.filter((item) => item.status == "inactive").length);
            setActive(response.data.filter((item) => item.status == "active").length);
        } catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getAll();
    }, []);

    return (
      <>
        {(auth.roleId == 1) 
         ? (<div className="row">
              <div className="col-md-4 mb-4 stretch-card transparent">
                  <div className="card card-tale">
                  <div className="card-body">
                      <p className="mb-4">Total</p>
                      <p className="fs-30 mb-2">{total}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div>
              <div className="col-md-4 mb-4 stretch-card transparent">
                  <div className="card card-dark-blue">
                  <div className="card-body">
                      <p className="mb-4">Active</p>
                      <p className="fs-30 mb-2">{active}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div>
              
              <div className="col-md-4 mb-4 stretch-card transparent">
                  <div className="card card-light-danger">
                  <div className="card-body">
                      <p className="mb-4">Inactive</p>
                      <p className="fs-30 mb-2">{inactive}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div>
              {/* <div className="col-md-3 mb-4 stretch-card transparent">
                  <div className="card card-light-danger">
                  <div className="card-body">
                      <p className="mb-4">Inactive</p>
                      <p className="fs-30 mb-2">{0}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div> */}
          </div>)
          : (<></>)}
      </>
    )
  }
  
  export default Stats