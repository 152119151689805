import { Link } from "react-router-dom";
// import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";

function Table() {

  const customStyles = {
    headCells: { 
      style: { 
        background: "red", 
        color: "white", 
        fontSize: 16, 
        fontWeight: 600,
        border: 0, 
      }
    },
    rows: {
      style: {
          height: '42px',
          fontSize: '14px'
      },
    },
    cells: {
      style: {
          width: "auto"
      },
    }, 

  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      width: "50px",
    },
    {
      name: 'Uploader',
      selector: row => row.user,
      width: "auto",
    },
    {
      name: 'Type',
      selector: row => row.type,
      width: "auto",
    },
    {
        name: 'Format',
        selector: row => row.format,
        width: "auto",
    },
    {
        name: 'url',
        selector: row => row.url,
        width: "auto",
    },
    {
        name: 'Date',
        selector: row => row.date,
        width: "auto",
    },
    {
        name: 'Actions',
        selector: row => row.actions,
        width: "auto",
    },
  ];

  const url = "/notifications";

  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);

  async function getAll(){
    try{
      // Axios API.
      const response = await axiosPrivate.get(url);
      // Print Out Responses.
      
      // Set Data.
      const formatedData = response?.data?.map((upload)=>{
        return {
          id: upload.id,
          user: upload.userId,
          type: upload.type,
          format: upload.format || " - ",
          url: upload.url,
          date: upload.createdAt || " - ",
          actions: " - - - ",
        }
      })
      setData(formatedData);

      // Set Pending.
      setPending(false);

    }catch(e){
      console.error(e);
    }
  }

  useEffect(()=> {
    getAll();
  }, []);

   
  return (
    <div>
        <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title text-danger">All Notifications</h4>
                  </div>

                  <div className="table-responsive pt-3">
                    {/* <DataTable 
                      columns={columns}
                      data={data}
                      className="table table-striped"
                      customStyles={customStyles}
                      progressPending={pending}
                      pagination
                      dense
                    /> */}
                  </div>

                </div>
              </div>
            </div>
    </div>
  );
}
export default Table;