import React from 'react'

function Create() {
  return (
    // <div>
    //     <div className="row">
    //         <div className="col-md-12 grid-margin stretch-card">
    //           <div className="card">
    //             <div className="card-body">
    //               <h4 className="card-title">Default form</h4>
    //               <div className="row">
    //                 <div className="col-md-6">
    //                     <form className="forms-sample">
    //                         <div className="row">
    //                             <div className="col-md-4">
    //                                 <div className="form-group">
    //                                     <label>First Name</label>
    //                                     <input type="text" className="form-control form-control-sm" placeholder="John"/>
    //                                 </div>
    //                             </div>
    //                             <div className="col-md-4">
    //                                 <div className="form-group">
    //                                     <label>Middle Name</label>
    //                                     <input type="text" className="form-control form-control-sm" placeholder="Smith"/>
    //                                 </div>
    //                             </div>
    //                             <div className="col-md-4">
    //                                 <div className="form-group">
    //                                     <label>Last Name</label>
    //                                     <input type="text" className="form-control form-control-sm" placeholder="John"/>
    //                                 </div>
    //                             </div>
    //                         </div>
                            
    //                         <div className="row">
    //                             <div className="col-md-6">
    //                                 <div className="form-group">
    //                                     <label>Email address</label>
    //                                     <input type="email" className="form-control form-control-sm" placeholder="Email"/>
    //                                 </div>
    //                             </div>
    //                             <div className="col-md-6">
    //                                 <div className="form-group">
    //                                     <label>Telephone NO</label>
    //                                     <input type="text" className="form-control form-control-sm" placeholder="Tel."/>
    //                                 </div>
    //                             </div>
    //                         </div>

                            
    //                         <div className="form-group">
    //                         <label for="exampleInputPassword1">Password</label>
    //                         <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"/>
    //                         </div>
    //                         <div className="form-group">
    //                         <label for="exampleInputConfirmPassword1">Confirm Password</label>
    //                         <input type="password" className="form-control" id="exampleInputConfirmPassword1" placeholder="Password"/>
    //                         </div>
    //                         <div className="form-check form-check-flat form-check-primary">
    //                         <label className="form-check-label">
    //                             <input type="checkbox" className="form-check-input"/>
    //                             Remember me
    //                         <i className="input-helper"></i></label>
    //                         </div>
    //                         <button type="submit" className="btn btn-primary mr-2">Submit</button>
    //                         <button className="btn btn-light">Cancel</button>
    //                     </form>
    //                 </div>
    //                 <div className="col-md-6"></div>
    //               </div>
                  
    //             </div>
    //           </div>
    //         </div>
    //     </div>
    // </div>
    <div className="modal fade" id="uploadModal" tabIndex="-1" role="dialog" aria-labelledby="uploadModal" aria-hidden="true">
        <div className="modal-dialog " role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                ...
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-danger">Save changes</button>
            </div>
            </div>
        </div>
    </div>
  );
}

export default Create;
