import { useEffect, useRef, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";
import Update from "./Update";
import $ from 'jquery';
import Delete from "./Delete";
import useAuth from "../../../Hooks/useAuth";
$.DataTable = require('datatables.net-bs5');


function Table() {

  const { auth } = useAuth();
  const tableRef = useRef()             
  const tableName = "table-rooms"
  const url = "/rooms";

  const [data, setData] = useState();

  const [updateId, setUpdateId] = useState("");
  const [roomData, setRoomData] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [imageId, setImageId] = useState("");


  
  let actions = `<button class="btn edit-btn" data-toggle="modal" data-target="#roomUpdateModal" ><i class="ti-pencil text-primary" ></i> </button> <b> - </b>                          
                  <button class="btn delete-btn" data-toggle="modal" data-target="#roomDeleteModal"><i class="ti-trash text-danger"></i> </button>`;
  if(auth.roleId == 2){
    actions = `<button class="btn edit-btn" data-toggle="modal" data-target="#roomUpdateModal" ><i class="ti-pencil text-primary" ></i> </button>`;
  }

  const columns =  [
      { title: "ID"},
      { title: "Prop."},
      { title: "Room"},
      { title: "Beds"},
      { title: "Adults"},
      { title: "Children"},
      { title: "Qty."},
      // { title: "Booked."},
      { title: "Price"},
      { title: "Price/Hr"},
      { title: "Discount"},
      { title: "Status."},
      { title: "Actions"},
  ];

  async function getAll(){
    try{
      // Axios API.
      const response = await axiosPrivate.get(url);

      // Set Data.
      const formatedData = await response?.data?.map((room)=>{
        if(auth.roleId == 2){
          if(room?.Property?.userId == auth.userId){
            return [
              room.id,
              (room?.Property?.name || " - "),
              room.roomType,
              room.beds,
              room.adults,
              room.children,
              room.limit,
              // 0,
              "₦"+room.price,
              "₦"+room.pricePerHour,
              room.discount || " - ",
              room.status || " - ",
              room.description || " - ",
              room
            ]
          }
          return false;
        }else {
          return [
            room.id,
            (room?.Property?.name || " - "),
            room.roomType,
            room.beds,
            room.adults,
            room.children,
            room.limit,
            // 0,
            "₦"+room.price,
            "₦"+room.pricePerHour,
            room.discount || " - ",
            room.status || " - ",
            room.description || " - ",
            room
          ]
        }
        
      })

      setData(formatedData.filter(function (item) {
        return item != false;
      }));

    }catch(e){
      console.error(e);
    }
  }

  async function setUpdateInformation(data){
    //console.log({value: 3, label: "something"});
  
    const imagesResponse =  await axiosPrivate.post("/rooms/images",
    JSON.stringify({
      roomId: data[0]
    }));

    setImageId(imagesResponse.data[0]?.id);
    setUpdateImage(imagesResponse.data[0]?.url);
    setUpdateId(data[0]);
    setRoomData(data);
  }

  function setDeleteInformation(data){
    setUpdateId(data[0]);
  }

  useEffect(() => { getAll() }, []);
  
  const table = $(tableRef.current).DataTable({
    data: data,
    columns: columns,
    dom: "<'d-flex justify-content-between'<B><f>>rt<'d-flex justify-content-between'<l><p>>i<'clear'>",
    pageLength: 5,
    lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
    buttons: [
      // { extend: 'pdf', className: 'btn btn-sm btn-dark' },
      { extend: 'csv', className: 'btn btn-sm btn-dark' },
    ],
    columnDefs: [
      {
          targets: -1,
          data: null,
          render: function () {
            return actions;
        }
      },
    ],
    drawCallback: () => {
      $('.form-control').addClass('form-control-sm');
      $('.active > .page-link').addClass('bg-dark');
    },
    destroy: true,     
  });
  $('#table-rooms tbody').on('click', 'tr', function () {
    if ($(this).parent("tr").hasClass('selected')) {
        $(this).removeClass('selected');
    } else {
        table.$("tr.selected").removeClass('selected');
        $(this).addClass('selected');
    }
  });
  $("#table-rooms tbody").on("click", "button", function () {
    let data = table.row($(this).parents("tr")).data();
    if(data) setUpdateInformation(data)
    if(data) setDeleteInformation(data)
  });
  


  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h4 className="card-title text-danger">All Rooms</h4>
              <button type="button" className="btn btn-danger btn-sm btn-icon-text" data-toggle="modal" data-target="#roomModal">
                <i className="ti-file btn-icon-prepend"></i> Add Room
              </button>
            </div>
            <div className="table-responsive pt-3">
              <table className="display table table-striped" width="100%" id={tableName} ref={ tableRef }></table>
            </div>
          </div>
        </div>
      </div>
      
      <Update roomId={updateId} roomData={roomData} roomProperty={{value: roomData[13]?.Property.id,  label: roomData[1]}} images={updateImage} imageId={imageId} />
      <Delete roomId={updateId} />
    </>
  );

}
export default Table;