import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './Components/Authentication/Login';
import Register from './Components/Authentication/Register';
import ForgotPassword from './Components/Authentication/ForgotPassword';
import ChangePassword from './Components/Authentication/ChangePassword';
import Authentication from './Components/Authentication/Authentication';
import Err404 from './Components/Errors/Err404';
import Core from './Components/Core/Core';
import Users from './Components/Core/Users/Users';
import Dashboard from './Components/Core/Dashboard/Dashboard';
import Authenticate from './Components/Authentication/Authenticate';
import Roles from './Components/Core/Roles/Roles';
import Amenities from './Components/Core/Amenities/Amenities';
import Bookings from './Components/Core/Bookings/Bookings';
import Categories from './Components/Core/Categories/Categories';
import Locations from './Components/Core/Locations/Locations';
import Rooms from './Components/Core/Rooms/Rooms';
import Properties from './Components/Core/Properties/Properties';
import Payments from './Components/Core/Payments/Payments';
import Transactions from './Components/Core/Transactions/Transactions';
import Uploads from './Components/Core/Uploads/Uploads';
import Notifications from './Components/Core/Notifications/Notifications';
import Organisations from './Components/Core/Organisations/Organisations';

function App() {
  return (
    <>
      <Routes>
      <Route element={<Authenticate/>}>
          <Route path='/' element={<Authentication/>}>
            <Route index element={<Login/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/register' element={<Register/>} />
            <Route path='/forgot' element={<ForgotPassword/>} />
            <Route path='/change-password/:token' element={<ChangePassword/>} />
          </Route>

        
          <Route path='/' element={<Core/>}>
            <Route path='/dashboard' element={<Dashboard/>} />
            <Route path='/users' element={<Users/>} />
            <Route path='/roles' element={<Roles/>} />
            <Route path='/amenities' element={<Amenities/>} />
            <Route path='/bookings' element={<Bookings/>} />
            <Route path='/clients' element={<Organisations/>} />
            <Route path='/categories' element={<Categories/>} />
            <Route path='/locations' element={<Locations/>} />
            <Route path='/rooms' element={<Rooms/>} />
            <Route path='/properties' element={<Properties/>} />
            <Route path='/payments' element={<Payments/>} />
            <Route path='/transactions' element={<Transactions/>} />
            <Route path='/uploads' element={<Uploads/>} />
            <Route path='/notifications' element={<Notifications/>} />
          </Route>
        </Route>

        <Route path='*' element={<Err404/>} />

      </Routes>
        
    </>
  );
}

export default App;
