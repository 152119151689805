import { useEffect, useState } from "react";
import { axiosPrivate } from "../../../Apis/axios";
import useAuth from "../../../Hooks/useAuth";

function Stats() {

    const url = "/bookings";
    const { auth } = useAuth();
    const [total, setTotal] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [cancelled, setCancelled] = useState(0);
    const [pending, setPending] = useState(0);

    async function getAllBookings(){
        try {
            const response = await axiosPrivate.get(url);
            setTotal(response.data.length);
            setCancelled(response.data.filter((item) => item.status == "cancelled").length);
            setPending(response.data.filter((item) => item.status == "pending").length);
            setCompleted(response.data.filter((item) => item.status == "completed").length);
        } catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getAllBookings();
    }, []);


    return (
      <>
        {(auth.roleId == 1) 
         ? (<div className="row">
              <div className="col-md-3 mb-4 stretch-card transparent">
                  <div className="card card-tale">
                  <div className="card-body">
                      <p className="mb-4">Total</p>
                      <p className="fs-30 mb-2">{total}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div>
              <div className="col-md-3 mb-4 stretch-card transparent">
                  <div className="card card-dark-blue">
                  <div className="card-body">
                      <p className="mb-4">Pending</p>
                      <p className="fs-30 mb-2">{pending}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div>
              
              <div className="col-md-3 mb-4 stretch-card transparent">
                  <div className="card card-light-blue">
                  <div className="card-body">
                      <p className="mb-4">Completed</p>
                      <p className="fs-30 mb-2">{completed}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div>
              <div className="col-md-3 mb-4 stretch-card transparent">
                  <div className="card card-light-danger">
                  <div className="card-body">
                      <p className="mb-4">Cancelled</p>
                      <p className="fs-30 mb-2">{cancelled}</p>
                      <p>{}</p>
                  </div>
                  </div>
              </div>
          </div>)
          : (<></>)}
      </>
    )
  }
  
  export default Stats