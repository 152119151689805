import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../../Apis/axios';
import { Widget } from '@uploadcare/react-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';
import useAuth from '../../../Hooks/useAuth';

const UPLOAD_PUBLIC_KEY = "d56c8b8e1e84d37ee09a";

function Create(props) {
    const url = "/properties";
    const { auth } = useAuth();
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Create");
    const [imageValue, setImageValue] = useState("");
    const [options, setOptions] = useState();
    const [states, setStates] = useState();
    const [countries, setCountries] = useState();

    useEffect(()=>{
        let opt = [];

        if(props.hasOwnProperty("opt")) opt = props.opt; 
        if(opt){
            const optionsArray = opt.map((item)=> { return (<option key={item.id} value={item.id}>{item.name}</option>) });
            setOptions(optionsArray);
        }

    }, [props])

    async function getCountries(){
        try{
          const countriesResponse =  await axiosPrivate.get("/countries");
          // Print out responses
          const countryList = countriesResponse?.data.map((item) => {
              return(
                <option key={item.id} value={item.id}>
                {item.name}
              </option>
            )
          });
        setCountries(countryList);
        }catch(e){
          console.error(e);
        }
    }
    
    async function getStates(event){
        const countryId = event.target.value
        //console.log(typeof countryId);
        if (countryId > 0) {
          try{
            const statesResponse = await axiosPrivate.get("/states/country/"+countryId);
            const stateList = statesResponse?.data.map((item) => {
              return(
                <option key={item.id} value={item.id}>
                {item.name}
              </option>
            )
          });
          setStates(stateList);
    
          }catch(e){
            console.error(e);
          }
        }else{
          setStates("");
        }
    }
    
    useEffect(()=>{
        getCountries();
    }, []);
    

    async function handleCreate(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)
        
        //console.log(auth.userId);

        const inputValues = {
            name: event.target.elements.name.value,
            email: event.target.elements.email.value,
            telephone: event.target.elements.telephone.value,
            categoryId: event.target.elements.category.value,
            stateId: event.target.elements.state.value,
            userId: auth.userId,
            status: event.target.elements.status.value,
            address: event.target.elements.address.value,
            description: event.target.elements.description.value
        }    

        //console.log(inputValues);
        
        try{
            // Axios API.
            const response = await axiosPrivate.post(url, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                });
                
                event.target.elements.submitBtn.disabled = false;
                setSubmit("Create");
                
                if ((response.status === 200) && (imageValue != "")){
                    
                    const uploadsInputValues = {
                        userId: auth.userId,
                        typeId: response.data.data.id,
                        type: 'property',
                        format: 'image/url',
                        url: imageValue.cdnUrl,
                    }  
                    await axiosPrivate.post("/uploads", JSON.stringify(uploadsInputValues));
                }

            // console.log(response?.data);
            // Print Out Responses.

            setSuccess("Property created successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Create");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }

  return (
    <div className="modal fade" id="propertyModal" tabIndex="-1" role="dialog" aria-labelledby="#propertyModal" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content border-0">
                <form onSubmit={handleCreate}>
                    <div className="modal-header py-2 text-white bg-dark">
                        <h5 className="modal-title" id="exampleModalLongTitle">Create Property</h5>
                    </div>
    
                    <div className="modal-body py-3">
                        <span className="text-danger small"><b>{error}</b></span>
                        <span className="text-success small"><b>{success}</b></span>

                        <div className="row py-3">

                            <div className="col-md-6 px-2">
                                <label htmlFor="nameInput" className='font-weight-medium'>Name: <span className='text-danger'>*</span></label>
                                <input type="text" id='nameInput' name='name' className='form-control form-control-sm'  placeholder='Name' required />
                            </div>

                            <div className="col px-2">
                                <label htmlFor="status" className='font-weight-medium'>Status: <span className='text-danger'>*</span></label>
                                <select name="status" id="status" className='form-control form-control-sm'>
                                    <option value="inactive">Inactive</option>
                                    <option value="active">Activate</option>
                                    <option value="pending">Pending</option>
                                </select>
                            </div>

                            <div className="col px-2">
                                <label htmlFor="category" className='font-weight-medium'>Category: <span className='text-danger'>*</span></label>
                                <select name="category" id="category" className='form-control form-control-sm'>
                                    {options}
                                </select>
                            </div>

                        </div>

                        <div className="row py-3">
                            <div className="col-md-4 px-2">
                                <label className='font-weight-medium'>Email: <span className='text-danger'>*</span></label>
                                <input type="email" name='email' className='form-control form-control-sm'  placeholder='Email' required />
                            </div>
                            
                            <div className="col-md-4 px-2">
                                <label className='font-weight-medium'>Telephone: <span className='text-danger'></span></label>
                                <input type="tel" name='telephone' className='form-control form-control-sm'  placeholder='Telephone' />
                            </div>
                        </div>

                        <div className="row py-3">

                            <div className="col px-2">
                                <label htmlFor="country" className='font-weight-medium'>Country: <span className='text-danger'>*</span></label>
                                <select name="country" id="country" onChange={getStates} className='form-control form-control-sm'>
                                    <option value="">None</option>
                                    {countries}
                                </select>
                            </div>

                            <div className="col px-2">
                                <label htmlFor="state" className='font-weight-medium'>State: <span className='text-danger'>*</span></label>
                                <select name="state" id="state" className='form-control form-control-sm'>
                                    <option value="">None</option>
                                    {states}
                                </select>
                            </div>

                            <div className="col-md-6 px-2">
                                <label htmlFor="address" className='font-weight-medium'>Address: <span className='text-danger'>*</span></label>
                                <input type="text" id='address' name='address' className='form-control form-control-sm'  placeholder='Address' required />
                            </div>

                        </div>
                        
                        <div className="row  py-3">

                            <div className="col-md-6 px-2">
                                <label htmlFor="description" className='font-weight-medium'>Description: <span className='text-danger'></span></label>
                                <textarea name="description"  className='form-control form-control-sm' id="description" cols="30" rows="3"></textarea>
                            </div>

                            <div className="col-md-6 px-2">
                                <label htmlFor="images" className='font-weight-medium'>Property Images: <span className='text-danger'></span></label>
                                <br />
                                <Widget 
                                    publicKey={UPLOAD_PUBLIC_KEY}
                                    tabs='file'
                                    customTabs={{preview: uploadcareTabEffects}}
                                    previewStep='true'
                                    imagesOnly='true'
                                    multiple='true'
                                    multipleMax='3'
                                    effects='crop'
                                    multipartPartSize='10480'
                                    onChange={(file) => setImageValue(file)}
                                    crop='free, 16:9, 4:3, 5:4, 1:1'
                                    required
                                />
                                {/* <input type="file" className='form-control form-control-sm' accept='.jpg,.png,.jpeg' id='images' name="images" multiple /> */}
                            </div>
                        </div>
                        
                    </div>

                    <div className="modal-footer py-1">
                        <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                        <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                            {submit}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
}

export default Create;