import React, { useEffect, useState } from 'react'
import { axiosPrivate } from '../../../Apis/axios';
import { Widget } from '@uploadcare/react-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';
import useAuth from '../../../Hooks/useAuth';

function Update(props) {

    const UPLOAD_PUBLIC_KEY = "d56c8b8e1e84d37ee09a";
    const url = "/states";
    const { auth } = useAuth();
    const [error, setError] = useState();  
    const [success, setSuccess] = useState();  
    const [submit, setSubmit] = useState("Update");
    const [imageValue, setImageValue] = useState("");

    const [options, setOptions] = useState();

    useEffect(()=>{
        let opt = [];

        if(props.hasOwnProperty("opt")) opt = props.opt; 

        if(opt){
            const optionsArray =  props.opt.map((item)=> {
                let selectedValue = "false";
                console.log(props.country, item.name)
                if(props.country == item.name) selectedValue = "selected";
                return (
                    <option key={item.id} value={item.id} selected={selectedValue} >
                        {item.name}
                    </option>
                )
            });
        
            setOptions(optionsArray)
        }
        
    }, [props])
    


    async function handleUpdate(event){
        event.preventDefault();
        event.target.elements.submitBtn.disabled = true;
        setSubmit(<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...</>)

        const inputValues = {
            id: event.target.elements.id.value,
            name: event.target.elements.name.value,
            countryId: event.target.elements.country.value,
        }

        try{
            // Axios API.
            const response = await axiosPrivate.put(url+"/"+inputValues.id, 
                JSON.stringify(inputValues), 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");

            // Print Out Responses.
            console.log(response?.data);

            if ((response.status === 200) && (imageValue != "")){
                    
                const uploadsInputValues = {
                    id: event.target.elements.imageId.value,
                    userId: auth.userId,
                    typeId: inputValues.id,
                    type: 'state',
                    format: 'image/url',
                    url: imageValue.cdnUrl,
                }
                // console.log(uploadsInputValues);
                // console.log("Here "+response);

                if(event.target.elements.imageId.value != ""){
                    await axiosPrivate.put("/uploads/"+uploadsInputValues.id, JSON.stringify(uploadsInputValues));
                    console.log("here noaaw");
                }else{
                    await axiosPrivate.post("/uploads", JSON.stringify(uploadsInputValues));
                }
            }

            setSuccess("Category updated successfully.");
            window.location.reload(false);

        }catch(e){
            event.target.elements.submitBtn.disabled = false;
            setSubmit("Update");
            setSuccess("");
            if (e.response.data.error.hasOwnProperty("errors")){
                setError(e.response.data.error.errors[0].message);
            }else{
                setError(e.response.data.error);
                console.error(e);
            }
        }
    }

    return (
        <div className="modal fade" id="locationUpdateModal" tabIndex="-1" role="dialog" aria-labelledby="locationUpdateModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <form onSubmit={handleUpdate}>
                        <div className="modal-header py-2 text-white bg-dark">
                            <h5 className="modal-title" id="exampleModalLongTitle">Update Location</h5>
                        </div>
        
                        <div className="modal-body py-3">
                        <span className="text-danger small"><b>{error}</b></span>
                        <span className="text-success small"><b>{success}</b></span>

                        <input type="hidden" name='id' defaultValue={props.locationId} />
                        <input type="hidden" name='imageId' defaultValue={props.imageId} />

                            <div className="row py-2">
                                <div className="col-md-6">
                                    <label htmlFor="titleInput" className='font-weight-medium'>State: <span className='text-danger'>*</span></label>
                                    <input type="text" defaultValue={props.name} id='titleInput' name='name' className='form-control form-control-sm'  placeholder='State name' required />
                                </div>
                                <div className="col-md-6"></div>
                            </div>

                            <div className="row py-2">
                                <div className="col-md-6">
                                    <label htmlFor="iconInput" className='font-weight-medium'>Country: <span className='text-danger'></span></label>
                                    <select name="country" id="" className='form-control form-control-sm'>
                                        <option value=""> None </option>
                                        {options}
                                    </select>
                                </div>
                                <div className="col-md-6"></div>
                            </div>

                            <div className="row py-2">
                                <div className="col-md-6">
                                    <label htmlFor="parentInput" className='font-weight-medium'>Image: <span className='text-danger'></span></label>
                                    <br />
                                    <Widget 
                                        publicKey={UPLOAD_PUBLIC_KEY}
                                        tabs='file'
                                        customTabs={{preview: uploadcareTabEffects}}
                                        previewStep='true'
                                        imagesOnly='true'
                                        multiple='true'
                                        multipleMax='1'
                                        effects='crop'
                                        multipartPartSize='10480'
                                        onChange={(file) => setImageValue(file)}
                                        crop='free, 16:9, 4:3, 5:4, 1:1'
                                        value={props.images}
                                    />
                                </div>
                                <div className="col-md-6"></div>
                            </div>
                        </div>

                        <div className="modal-footer py-1">
                            <button type="button" className="btn btn-sm btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
                            <button type="submit" name="submitBtn" className="btn btn-danger btn-sm">
                                {submit}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      );
    }

export default Update;