import { Navigate, Outlet, useLocation } from 'react-router-dom';
import "jszip";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.html5.js";
import useAuth from '../../Hooks/useAuth';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

function Core() {
    const { auth } = useAuth();
    const location = useLocation();

    // console.log(auth);

  return (<>

    {   auth?.accessToken
            ? <div>
                <div className="container-scroller">
                    <Header/>
                    <div className="container-fluid page-body-wrapper"> 
                        <Sidebar/>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <Outlet />
                            </div>
                            <Footer/>
                        </div>   
                    </div>
                </div>
            </div>
            : <Navigate to="/login" state={{ from: location }} replace />
    }
    </>
  );
}

export default Core;
